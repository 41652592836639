import { IFieldConfig, IFormField } from '@c-fields/common';

import { IFieldFactory } from '../../common/interfaces/field-factory.interface';

import { LastNameField } from './last-name.field';


export class LastNameFieldFactory implements IFieldFactory {

  public readonly isComplexField = false;

  public getFieldWithConfig(config: IFieldConfig): IFormField {
    return new LastNameField(config);
  }

}
