<div class="c-app-content">
  <ng-container *ngIf="storeService?.setup?.showTopbar">
    <app-top-bar class="custom-topbar"></app-top-bar>
  </ng-container>

  <div class="message-box warn" *ngIf="formElement.invalid && formElement.submitted">
    <b>{{ 'validation.formValidationErrorTitle' | translate }}</b>

    <p>{{ 'validation.formValidationErrorMessage' | translate }}</p>
  </div>

  <app-sender-message-element *ngIf="storeService?.setup?.includeEmployee"
                              [pageType]="pageType">
  </app-sender-message-element>

  <form cForm [formGroup]="form" (generalSectionError)="openGeneralSection()" #formElement="ngForm">
    <ng-container *ngIf="generalFields">
      <app-person-section [form]="generalFormGroup">
      </app-person-section>
    </ng-container>

    <ng-container *ngIf="privateFields">
      <app-form-section [form]="privateFormGroup"
                         [fields]="privateFields"
                         [name]="'receiver.categories.home' | translate"
                         icon="personal"
                         [collapsable]="collapsableSections">
      </app-form-section>
    </ng-container>

    <ng-container *ngIf="workFields">
      <app-form-section [form]="workFormGroup"
                         [fields]="workFields"
                         [name]="'receiver.categories.work' | translate"
                         icon="work"
                         [collapsable]="collapsableSections">
      </app-form-section>
    </ng-container>

    <app-consents-section [pageType]="pageType" data-cy="preferences">
    </app-consents-section>

    <div class="message-box error" *ngIf="(serverErrorMessage | async) as error">
      <b>{{ 'global.internalServerError' | translate }}</b>
      <p>{{ error }}</p>

      <p>{{ 'global.internalServerErrorMessage' | translate }}</p>
    </div>

    <div class="c-bottom-container">
      <button (click)="validate()" class="btn btn-primary c-btn-send g-btn-bold custom-btn-primary custom-send-button"
              data-cy="answering-submit"
              type="submit">
        {{'receiver.buttons.send' | translate}}
      </button>
    </div>

    <app-info background></app-info>
    <app-popover></app-popover>
  </form>
</div>
