<ng-container *ngFor="let input of items; let i = index">
  <div class="c-input-container">
    <label class="c-input-label" *ngIf="input.labelLangKey || input.label">
      <ng-container *ngIf="input.labelLangKey else defaultLabel">
        {{ input.labelLangKey | translate }}
      </ng-container>
      <ng-template #defaultLabel>
        {{ input.label }}
      </ng-template>
    </label>

    <div class="c-component-input custom-authentication-digit-input">
      <input [(ngModel)]="model[i]"
             (ngModelChange)="changed()"
             (input)="onInput($event, i)"
             (keydown)="onKeydown($event, i)"
             [placeholder]="!!input.inputLangKey ? (input.inputLangKey | translate) : input.placeholder"
             [attr.inputmode]="input.mode || 'numeric'"
             [maxlength]="input.maxLength"
             [name]="input.name"
             class="c-code-input custom-authentication-input"
             #input
             [autofocus]="i === 0 ? true : null"
      >
    </div>
  </div>
</ng-container>
