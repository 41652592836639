import { Directive, ElementRef, Input, OnInit } from '@angular/core';

import { iconsLibrary } from '@common/consts';

import { StoreService } from '../../../../app/core/services/store.service';


@Directive({
  selector: '[appIcon]',
  standalone: true,
})
export class IconDirective implements OnInit {

  @Input()
  public appIcon = '';

  @Input()
  public size = 20;

  public customSvg: string;
  public svg: string;

  constructor(
    private _elementRef: ElementRef,
    private _storeService: StoreService,
  ) {
  }

  public ngOnInit() {
    this.customSvg = this._storeService.customIcons && this._storeService.customIcons[this.appIcon];
    this._getSvgHTML();
    this._setIconSizeIfDefaultIcon();
    this._insertIntoHTML();
  }

  private _getSvgHTML(): void {
    this.svg = this.customSvg || iconsLibrary[this.appIcon] || '';
  }

  private _setIconSizeIfDefaultIcon(): void {
    if (!this.customSvg) {
      this.svg = this.svg
        .replace(/width="\d*"/i, `width="${this.size}"`)
        .replace(/height="\d*"/i, `height="${this.size}"`);
    }
  }

  private _insertIntoHTML(): void {
    this._elementRef.nativeElement.innerHTML = this.svg;
  }

}
