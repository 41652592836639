import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpClient, HttpContext, HttpHeaders, HttpParams } from '@angular/common/http';


type httpParams = HttpParams| {[param: string]: string | number | boolean | readonly (string|number|boolean)[]};

// Created in scope of task
// https://www.notion.so/complero/undefined-stored-in-payload-for-request-notification_event-ef12f746ddf148d1a56d634d00fdfa69
// to filter out null && undefined values from the params in the request

@Injectable()
export class CompleroHttpClient extends HttpClient {
  request(first: any,
    url?: string,
    options: {
            body?: any,
            headers?: HttpHeaders|{[header: string]: string | string[]},
            context?: HttpContext,
            observe?: 'body'|'events'|'response',
            params?: httpParams,
            reportProgress?: boolean,
            responseType?: 'arraybuffer'|'blob'|'json'|'text',
            withCredentials?: boolean,
          } = {},
  ): Observable<any> {

    if (!!options.params) {
      options.params = filterNotPresentedParams(options.params as HttpParams);
    }

    return super.request(first, url, options);
  }
}

function filterNotPresentedParams(params: httpParams): httpParams {
  if (params instanceof HttpParams) {
    params
      .keys()
      .forEach((key) => {
        const values = (params as HttpParams)
          .getAll(key)
          .filter((value) => isValuePresented(value));

        if (!values.length) {
          params = (params as HttpParams).delete(key);
        }
      });
  } else {
    Object.keys(params)
      .filter((key) => !isValuePresented(params[key]))
      .forEach((key) => {
        delete params[key];
      });
  }

  return params;
}

function isValuePresented(value: unknown): boolean {
  return value !== undefined && value !== null;
}
