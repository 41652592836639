import { Consent } from '../../../classes/consent';
import { ConsentType } from '../../../enums/consent.type';


export function filterAppointmentConsents(
  consents: Consent[],
): Consent[] {
  return consents.filter((consent: Consent) => {
    return (consent.type === ConsentType.BookAppointment);
  });
}
