import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  Input,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {
  ControlContainer,
  FormControlStatus,
  FormGroup,
  FormGroupName,
  FormsModule,
} from '@angular/forms';
import { NgForOf, NgIf } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';
import { FieldInputType } from '@c-fields/common';

import { Observable } from 'rxjs';


import { CFormModule } from '@c-form';
import { NzSelectModule } from 'ng-zorro-antd/select';

import { CMP_CONTROL_PATH } from '../../c-form/tokens/control-path.token';
import { FormFieldModule } from '../../form-field/form-field.module';
import { FieldControl } from '../../form-field';

import { processCountries } from './process-countries.helper';


@Component({
  selector: 'app-address-form-item',
  templateUrl: './address-form-item.component.html',
  styleUrls: ['./address-form-item.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    FormsModule,
    CFormModule,
    FormFieldModule,
    NgForOf,
    NzSelectModule,
    NgIf,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: FieldControl,
      useExisting: AddressFormItemComponent,
    },
  ],
})
export class AddressFormItemComponent implements FieldControl, AfterViewInit {

  @Input()
  public showAddressExtra3: boolean = false;

  public countryOptions: { value: string, label: string }[] = processCountries();
  public inputType = FieldInputType;
  public ngControl = null;

  private _controlContainer: FormGroupName = inject(ControlContainer) as FormGroupName;
  private _controlPath = inject(CMP_CONTROL_PATH);
  private _required = false;
  private _disabled = false;

  @ViewChildren(FieldControl)
  private _fieldControls: QueryList<FieldControl>;

  public get name(): string {
    return this._controlPath.path;
  }

  public get formGroup(): FormGroup {
    return this._controlContainer.control;
  }

  public get required(): boolean {
    return this._required;
  }

  public get disabled(): boolean {
    return this._disabled;
  }

  public get statusChanges(): Observable<FormControlStatus> {
    return this.formGroup.statusChanges;
  }

  private _cdRef = inject(ChangeDetectorRef);

  public ngAfterViewInit(): void {
    this._updateState();

    this.formGroup
      .statusChanges
      .subscribe(() => {
        this._updateState();

        this._cdRef.markForCheck();
      });
  }

  private _updateState(): void {
    // update disabled flag
    this._disabled = this.formGroup.disabled;

    // update required flag
    const controlsArray = Array.from(this._fieldControls);

    this._required = controlsArray
      .some((control) => {
        return control.required;
      });
  }
}
