import { IFieldConfig, IFormField } from '@c-fields/common';

import { IFieldFactory } from '../../common/interfaces/field-factory.interface';

import { TitleField } from './title.field';


export class TitleFieldFactory implements IFieldFactory {

  public readonly isComplexField = false;

  public getFieldWithConfig(config: IFieldConfig): IFormField {
    return new TitleField(config);
  }

}
