import { InfoItem } from './Info-item';


export class FullAddress extends InfoItem {

  city: string;
  house: string;
  addition: string;
  country: string;
  postcode: string;
  street: string;
  extra1?: string;
  extra2?: string;
  extra3?: string;

  constructor(data: FullAddress) {
    super(data);
    Object.assign(this, data);
  }

  isEmpty?() {
    return [
      String(this.house),
      this.addition,
      this.country,
      this.city,
      String(this.postcode),
      this.street,
    ].every((x) => !x || x.trim() === '');
  }

}


