<div class="c-app-content">
  <app-topbar class="custom-topbar" *ngIf="storeService?.setup?.showTopbar"
    lineTwo="{{storeService?.contractNumber ? storeService.contractNumber : null}}"></app-topbar>

  <ng-container *ngIf="storeService?.setup?.includeEmployee && !showLoader">
    <app-sender-message-element class="sender-message" [pageType]="pageType">
    </app-sender-message-element>
  </ng-container>

  <div class="c-header-image-container x-verification-company-header">
    <ng-container *ngIf="storeService?.sender?.person?.companyLogo">
      <img [src]="storeService?.sender?.person?.companyLogo" alt="">
    </ng-container>
    <div class="c-title">{{"birthday.title" | translate}}</div>
  </div>
  <div class="c-container c-box-container">
    <div class="c-content-container">
      <p>{{"birthday.description" | translate}}</p>
      <p *ngIf="!showLoader">{{"birthday.helpText" | translate}}</p>
      <div *ngIf="showLoader" class="middle">
        <div class="lds-dual-ring">
          <div class="lock-container">
            <div class="shackle"></div>
            <div class="lock">
              <div class="key-hole">

              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!showLoader" class="c-component-container">
        <app-birthday-verification [(ngModel)]="birthdayModel"
                        (ngModelChange)="changed()"
                        (keyup)="keyup($event)"
                        name="birthday">
        </app-birthday-verification>
      </div>

      <div *ngIf="showVerificationError" class="c-error-dialog">
        <b>{{"birthday.verificationErrorTitle" | translate}}</b>
        <p class="c-description">{{"birthday.verificationErrorDescription" | translate}}</p>
        <b *ngIf="phoneNumber"><a href="tel:{{phoneNumber}}">{{phoneNumber}}</a></b><br>
        <b *ngIf="link"><a [href]="link">{{anchor}}</a></b>
      </div>
      <div *ngIf="showTooManyRequestError" class="c-error-dialog">
        <b>{{ 'birthday.tooManyRequestErrorTitle' | translate }}</b>
        <p class="c-description">
          {{ 'birthday.tooManyRequestErrorDescription' | translate }}
          {{ storeService?.sender?.person.companyShortName || storeService?.sender?.person.company }}.
        </p>
        <ng-container *ngIf="tooManyRequestLink">
          <br>
          <b><a href="tooManyRequestLink">{{tooManyRequestLink}}</a></b>
        </ng-container>
      </div>
      <div *ngIf="showInputError" class="c-error-dialog">
        <b>{{"birthday.inputErrorTitle" | translate}}</b>
        <p class="c-description">
          {{"birthday.inputErrorDescription" | translate}}
          <ng-container *ngIf="phoneNumber">
            {{"birthday.inputErrorContactUs" | translate}}
          </ng-container>
        </p>
        <b *ngIf="phoneNumber"><a class="c-error-link" href="tel:{{phoneNumber}}">{{phoneNumber}}</a></b><br>
        <b *ngIf="link"><a [href]="link">{{anchor}}</a></b>
      </div>

      <div *ngIf="!showLoader" class="c-button-container">
        <button class="btn btn-primary btn-lg btn-block" (click)="onSubmit($event)" [disabled]="!enableSubmitButton"
          id="submitBirthday">
          {{"birthday.verifyButton" | translate}}
        </button>
      </div>

    </div>

  </div>

  <app-info background></app-info>
</div>
