<div class="c-app-content">
  <div class="c-main">
    <div class="c-content">
      <div class="c-img-container">
        <div class="c-img">
          <div [appIcon]="'success'" [size]="34" data-cy="done-icon"></div>
        </div>
      </div>
      <div class="c-main-title">
        {{ 'confirmation.thanks' | translate }}<br>
        {{ 'confirmation.success' | translate }}
      </div>
      <div class="c-main-subtitle"></div>

      <div class="success-msg">
        <div [appIcon]="'success'" [size]="24" class="p-check"></div>
        <div>{{ 'confirmation.provinzial-msg' | translate }}</div>
      </div>

      <div class="cta-block" *ngIf="sent || !showConsents">
        <p class="before-banner-text">
          <b>{{'customer.cta-header' | translate}}</b>
          <br><br>{{'customer.cta-text' | translate}}
        </p>
        <br>
        <div class="c-button-container">
          <button class="btn btn-primary custom-btn-cta"
                  (click)="openCTALink()">
            {{'customer.buttons.cta-btn' | translate}}
          </button>
        </div>
      </div>

    </div>
  </div>
  <ng-container *ngIf="showConsents && !sent">
    <app-collapsable-section
      class="appointment-consents book-appointment"
      [title]="'preference.provinzial_title' | translate"
      [collapsable]="false"
      [icon]="'calendar'">
      <app-consents-list [consents]="appointmentConsents"
                         [pageType]="pageType"
                         (changed)="consentsChanged($event)">
      </app-consents-list>
    </app-collapsable-section>

    <div class="c-bottom-container">
      <button (click)="submit()" class="btn btn-primary c-btn-send g-btn-bold custom-btn-primary custom-send-button"
              data-cy="answering-submit"
              type="submit">
        {{'receiver.buttons.send' | translate}}
      </button>
    </div>
  </ng-container>
</div>
