import { FormGroup } from '@angular/forms';

import { Sections } from '@c-fields/common';


/**
 * createForm uses IFormField and creates FormGroup from them.
 */
export function createForm(sections: Sections): FormGroup {
  const formGroup = new FormGroup({});

  Object.keys(sections).forEach((section) => {
    const group = new FormGroup({});
    formGroup.addControl(section, group);

    sections[section].forEach((field) => {
      group.addControl(field.type, field.formArray);
    });
  });

  return formGroup;
}
