import { FormArray, FormControl, FormGroup } from '@angular/forms';

import {
  BaseField,
  createFieldInput,
  FieldInputType,
  IFieldInput,
  IFieldsData,
  IFormField,
  Section,
} from '@c-fields/common';
import { CValidators } from '@common/helpers/validators';

import { FormControlBuilder } from '../../common/builders/form-control.builder';

import { IAddressData } from './interfaces/data.interface';


export class AddressField extends BaseField<IAddressData, FormGroup> implements IFormField {

  public addEmptyFormControl() {
    const addressGroup = this._createNewFormGroup(this._nextIndex, {
      street: '',
      house_number: '',
      country: 'DE',
      zip_code: '',
      city: '',

      addition: '',
      extra3: '',
    });

    this._formArray.push(addressGroup);
  }

  public toJSON(): IFieldInput[] {
    return this._formArray.value
      .filter((value) => !this._isValueEmpty(value))
      .reduce((acc: IFieldInput[], value: any, index: number) => {
        // country
        acc.push(
          createFieldInput(
            FieldInputType.Country,
            index,
            value[FieldInputType.Country],
          ),
        );

        // city
        acc.push(
          createFieldInput(
            FieldInputType.City,
            index,
            value[FieldInputType.City],
          ),
        );

        // street
        acc.push(
          createFieldInput(
            FieldInputType.Street,
            index,
            value[FieldInputType.Street],
          ),
        );

        // zipcode
        acc.push(
          createFieldInput(
            FieldInputType.ZipCode,
            index,
            value[FieldInputType.ZipCode],
          ),
        );

        // houseNumber
        acc.push(
          createFieldInput(
            FieldInputType.HouseNumber,
            index,
            value[FieldInputType.HouseNumber],
          ),
        );

        // Addition
        acc.push(
          createFieldInput(
            FieldInputType.Addition,
            index,
            value[FieldInputType.Addition],
          ),
        );

        // Extra3
        acc.push(
          createFieldInput(
            FieldInputType.Extra3,
            index,
            value[FieldInputType.Extra3],
          ),
        );

        return acc;
      }, []) as IFieldInput[];
  }

  public addEmptyFormControlIfNeeded(): void {
    if (!this._canAddEmptyFormControl) {
      return;
    }

    this.addEmptyFormControl();
  }

  public _makeFormArray(): FormArray<FormGroup> {
    const formArray = this._formArray;

    if (Array.isArray(this._initialValues) && this._initialValues.length > 0) {
      this._initialValues.forEach((value: IAddressData, index) => {
        const addressGroup = this._createNewFormGroup(index, value);

        formArray.push(addressGroup);
      });
    }

    return formArray;
  }

  protected _isValueEmpty(value: IAddressData): boolean {
    return !value
      || (
        !value.city
        && !value.street
        && !value.zip_code
        && !value.house_number
        && !value.addition
      );
  }

  protected _setInitialValues(values: IFieldsData): void {
    switch (this._config.section) {
      case Section.Private: {
        this._initialValues = values?.home?.fullAddresses || [];

        break;
      }
      case Section.Work: {
        this._initialValues = values?.work?.fullAddresses || [];

        break;
      }
    }
  }

  protected _createNewFormGroup(index: number, value: IAddressData): FormGroup {
    // main controls, should be required
    const countryControl = this._createNewFormControl(index, value.country);
    const cityControl = this._createNewFormControl(index, value.city);
    const streetControl = this._createNewFormControl(index, value.street);
    const zipCodeControl = this._createNewFormControl(index, value.zip_code);

    // secondary controls, can be optional
    const additionControl = this._createSecondaryFormControl(value.addition);
    const extra3Control = this._createSecondaryFormControl(value.extra3);
    const houseNumberControl = this._createSecondaryFormControl(value.house_number);
    houseNumberControl.addValidators(CValidators.houseNumber);

    return new FormGroup({
      [FieldInputType.City]: cityControl,
      [FieldInputType.Country]: countryControl,
      [FieldInputType.Street]: streetControl,
      [FieldInputType.HouseNumber]: houseNumberControl,
      [FieldInputType.ZipCode]: zipCodeControl,
      [FieldInputType.Addition]: additionControl,
      [FieldInputType.Extra3]: extra3Control,
    }, {
      validators: [
        CValidators.zipCode(countryControl, zipCodeControl),
      ],
    });
  }

  protected _createSecondaryFormControl<TCValue extends unknown = unknown>(
    value: TCValue,
  ): FormControl<TCValue> {
    const builder = new FormControlBuilder();

    if (this.config.readonly) {
      builder.makeDisabled();
    }

    builder.withValue(value);

    return builder.build<TCValue>();
  }

}
