import {
  DestroyRef,
  Directive,
  inject,
  Input,
  OnInit,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';

import { FormDirective } from '../form.directive';
import { CMP_CONTROL_PATH } from '../../tokens/control-path.token';
import { IFormControlPath } from '../../interfaces/form-control-path.interface';


@Directive()
export abstract class BaseControlDirective implements OnInit {

  @Input()
  public skipPath = false;

  protected abstract get _control(): AbstractControl;

  protected readonly _cForm = inject(FormDirective);
  protected readonly _controlPath: IFormControlPath = inject(CMP_CONTROL_PATH);

  protected _destroyRef = inject(DestroyRef);

  public ngOnInit(): void {
    this._initControl();
  }

  protected _setControlName(name: string | number) {
    if (name === null || name === undefined) {
      throw new Error('Control Name Error');
    }

    // do not append current control name as part of controlPath
    if (this.skipPath) {
      this._skipControlPath();

      return;
    }

    this._controlPath.set(String(name));
  }

  protected _skipControlPath() {
    this._controlPath.skip();
  }

  protected abstract _initControl(): void;

}
