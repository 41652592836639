<div class="c-app-content">
  <div class="c-container">
    <div class="c-error-dialog">
      <b>{{"expired.title" | translate}}</b>
      <p class="c-description">{{"expired.descriptionLine1" | translate}}</p>
      <br>
      <p class="c-description">{{"expired.descriptionLine2" | translate}}</p>
    </div>
    <br>
    <br>
    <app-error-footer></app-error-footer>
  </div>
</div>
