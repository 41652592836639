import { IFieldConfig, IFormField } from '@c-fields/common';

import { IFieldFactory } from '../../common/interfaces/field-factory.interface';

import { AddressField } from './address.field';


export class AddressFieldFactory implements IFieldFactory{

  public readonly isComplexField = true;

  public getFieldWithConfig(config: IFieldConfig): IFormField {
    return new AddressField(config);
  }

}
