import { InfoItemType } from '../enums/info-item.type';
import { CategoryType } from '../enums/category.type';


export class InfoItem {

  readonly type: InfoItemType;

  categoryType?: CategoryType;

  iconName?: string;
  listValue?: string | number;
  isSaved?: boolean;
  isFilledOut?: boolean;
  isReadOnly?: boolean;
  isRequired?: boolean;
  hasError?: boolean;
  isClearedOut?: boolean;
  isMobile?: boolean;

  constructor(data: InfoItem) {
    Object.assign(this, data);
  }

  isEmpty?();

}

