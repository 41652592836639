import { ModuleWithProviders, NgModule } from '@angular/core';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';

import { TranslationLoader } from './classes/translation-loader';
import { LangService } from './services/lang.service';


@NgModule({
  imports: [TranslateModule],
  exports: [TranslateModule],
})
export class TranslationModule {

  static forRoot(): ModuleWithProviders<TranslationModule> {
    const config = {
      loader: {
        provide: TranslateLoader,
        useClass: TranslationLoader,
        deps: [HttpClient],
      },
    };

    const translateModuleProviders = TranslateModule
      .forRoot(config)
      .providers;

    return {
      ngModule: TranslationModule,
      providers: [
        ...translateModuleProviders,
      ],
    };
  }

  // LangService must be there, because on "/" page we don't have any injects for it
  // and its constructor won't be executed
  constructor(private _langService: LangService) {}

}
