import { Directive } from '@angular/core';
import { NgControl } from '@angular/forms';

import { Observable } from 'rxjs';


@Directive()
export abstract class FieldControl {

  public readonly required: boolean;
  public readonly disabled: boolean;
  public readonly statusChanges: Observable<any> | null;
  public readonly ngControl: NgControl | null;

}
