import { iconList } from '@common//consts';

import { CategoryInterface } from '../../../libs/common/interfaces/category.interface';
import { CustomLegal } from '../../../libs/common/classes/custom-legal';
import { BaseStyleFactory } from '../factories/base-styles.factory';
import { CustomIconsFactory } from '../factories/icons.factory';


export class MainAdapter {

  public static processData(data) {

    const response = { ...data };

    const hasbirthday = response.contact?.birthday || '';
    if (response.contact?.profile_sections?.private) {
      response.contact.contact_sections.private.birthday = hasbirthday;
    }

    const senderWork = this.processCategory(response.shared_profile?.profile_sections?.work, '');
    const senderHome = this.processCategory(response.shared_profile?.profile_sections?.home, '');

    return {
      sender: {
        id: response.shared_profile?.id,
        person: {
          salutation: response.shared_profile?.salutation,
          title: response.shared_profile?.title,
          position: response.shared_profile?.position || '',
          company: response.shared_profile?.company_name || '',
          companyShortName: response.company?.name || '',
          companyLogo: response.shared_profile?.company_logo || '',
          website: response.company?.website || '',
          phone: senderWork?.phoneNumbers[0] || '',
          email: senderWork?.emails[0] || '',
          name: {
            firstName: response.shared_profile?.first_name,
            lastName: response.shared_profile?.last_name,
          },
          nameSuffix: response.shared_profile?.name_suffix || '',
          birthday: response.shared_profile?.profile_sections?.private?.birthday,
          imageUrl: response.shared_profile?.image_url?.match('//')
            ? response.shared_profile?.image_url
            : (response.shared_profile?.image_url ? `//${response.shared_profile?.image_url}` : null),
          imprintLink: response.imprint_link || '',
          privacyLink: response.privacy_link || '',
        },
        message: response.message,
        work: senderWork,
        home: senderHome,
      },
      receiver: {
        id: response.contact?.native_id || '',
        handler_id: response.contact?.handler_id || '',
        person: {
          salutation: response.contact?.salutation || '',
          title: response.contact?.title || '',
          position: response.contact?.position || '',
          company: response.contact?.company || '',
          website: response.contact?.website || '',
          name: {
            firstName: response.contact?.first_name || '',
            lastName: response.contact?.last_name || '',
          },
          nameSuffix: response.contact?.name_suffix || '',
          birthday: response.contact?.birthday || '',
          imageUrl: response.contact?.image_url?.match('//')
            ? response.contact?.image_url
            : (response.contact?.image_url ? `//${response.contact?.image_url}` : null),
          tags: response.contact?.tags || [''],
          data: response.contact?.data || [''],
        },
        message: null,
        work: this.processCategory(response.contact?.contact_sections?.work || '', ''),
        home: this.processCategory(response.contact?.contact_sections?.private || '', hasbirthday),
      },
      flow: response.flow,
      baseStyles: BaseStyleFactory.adaptBasicStyles(response?.base_style),
      customCSS: response.custom_css,
      customLegal: response.company ? this.processLegalSingleLanguage(response.company) : undefined,
      customCopy: response.custom_copy ? this.processCustomCopy(response.custom_copy) : undefined,
      contractNumber: response.contract_number,
      birthdayVerification: response.birthdayVerification,
      postcodeVerification: response.postcodeVerification,
      features: response.features,
      icons: this.processCustomIcons(response.icons),
      confirmationRequested: response.company?.request_email_confirmation || false,
    };
  }

  private static processLegalSingleLanguage(companyData: {
    ac_legal_description: string
    ac_legal_fine_print: string,
    ac_legal_subject: string
  }) {
    const customLegal = new CustomLegal();
    const langCode = 'de';

    if (companyData.ac_legal_subject && companyData.ac_legal_subject.trim() !== '') {
      customLegal[langCode] = { ...customLegal[langCode], subject: companyData.ac_legal_subject };
    }

    if (companyData.ac_legal_description && companyData.ac_legal_description.trim() !== '') {
      customLegal[langCode] = { ...customLegal[langCode], description: companyData.ac_legal_description };
    }

    if (companyData.ac_legal_fine_print && companyData.ac_legal_fine_print.trim() !== '') {
      customLegal[langCode] = { ...customLegal[langCode], additional: companyData.ac_legal_fine_print };
    }

    return customLegal;
  }

  private static processCategory(categoryData: any = {}, birthday: string): CategoryInterface {
    const events = [];
    if (birthday != '') {
      events.push(birthday);
    }

    // This is a workaround for the structural difference in contact and user phone numbers
    // TODO: Clarify desired structure and adapted accordingly
    if (categoryData.phones) {
      categoryData.phone_numbers = categoryData.phones.map((phone) => phone.number);
      delete categoryData.phones;
    }

    return {
      // for now, we treat all addresses as full addresses to counter act the fact that the backend currently
      // does not accept the 'full_address' key.
      // TODO: Remove once backend situation is clarified

      addresses: [], // categoryData.addresses || [],
      fullAddresses: categoryData.full_addresses || categoryData.addresses || [], // categoryData.full_addresses || [],
      emails: categoryData.emails || [],
      phoneNumbers: categoryData.phone_numbers || [],
      mobileNumbers: categoryData.mobile_numbers || [],
      faxes: categoryData.fax_numbers || [],
      urls: categoryData.urls || [],
      events: events,
    };
  }

  private static processCustomIcons(icons: Icons): { [key: string]: string } {
    const custom: { [key: string]: string } = {} as { [key: string]: string };

    iconList.forEach((icon) => {
      if (icons[icon] && icons[icon] !== '') {
        custom[icon] = CustomIconsFactory.parseSvg(icons[icon]);
      }
    });

    return custom;
  }

  private static processCustomCopy(data: { [key: string]: string }[]): { [key: string]: any } {
    if (!data) {
      return null;
    }

    return Object.keys(data).reduce((acc, key) => {

      try {
        const parsedData = JSON.parse(data[key]);

        return {
          ...acc,
          [key.toLowerCase()]: parsedData,
        };
      } catch {
        return acc;
      }
    }, {});
  }
}

export interface Icons {
  place: string;
  org: string;
  link: string;
  phone: string;
  mobile: string;
  fax: string;
  event: string;
  mail: string;
  work: string;
  personal: string;
  preferences: string;
  shared: string;
  edit: string;
  more: string;
  delete: string;
  clear: string;
  avatar_placeholder: string;
  photo_placeholder: string;
  add_photo: string;
  arrow_up: string;
  arrow_down: string;
  arrow_left: string;
  arrow_right: string;
  csv: string;
  info: string;
  tel_screen: string
  tel_doc: string;
}

