import { Component, inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';

import { environment } from '../../environments/environment';


@Component({
  selector: 'app-error-footer',
  templateUrl: './error-footer.component.html',
  styleUrls: ['./error-footer.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
  ],
})
export class ErrorFooterComponent {

  public readonly currentYear = (new Date()).getFullYear();

  private _documentRef: Document = inject(DOCUMENT);

  public onSetAppointmentClick(): void {
    this._documentRef.defaultView?.open(environment.appointmentUrl, '_blank');
  }

}
