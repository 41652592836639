import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { IconDirective } from '@common/directives';
import { TranslateModule } from '@ngx-translate/core';

import { FormControlNameDirective } from './directives/controls/form-control-name.directive';
import { FormArrayNameDirective } from './directives/controls/form-array-name.directive';
import { FormGroupDirective } from './directives/controls/form-group.directive';
import { FormDirective } from './directives/form.directive';
import { FormGroupNameDirective } from './directives/controls/form-group-name.directive';


@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IconDirective,
    TranslateModule,
  ],
  exports: [
    ReactiveFormsModule,

    // FormControls
    FormControlNameDirective,
    FormArrayNameDirective,
    FormGroupDirective,
    FormDirective,
    FormGroupNameDirective,
  ],
  declarations: [
    // ControlErrorComponent,

    FormDirective,
    FormControlNameDirective,
    FormArrayNameDirective,
    FormGroupDirective,
    FormGroupNameDirective,
  ],
})
export class CFormModule {

}
