import { ViewportScroller } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';

import { Observable, startWith, Subject, zip } from 'rxjs';
import { filter } from 'rxjs/operators';


@Injectable()
export class ScrollToError {

  private _formGroup: FormGroupDirective;
  private readonly _viewport = inject(ViewportScroller);
  private readonly _generalSectionError$ = new Subject<void>();

  public get generalSectionError$(): Observable<void> {
    return this._generalSectionError$.asObservable();
  }

  public init(formGroup: FormGroupDirective): void {
    this._formGroup = formGroup;

    this._listenFormSumit();
  }

  private _scrollToTop() {
    this._viewport.scrollToPosition([0, 0]);
  }

  private _listenFormSumit(): void{
    zip(
      this._formGroup.statusChanges.pipe(startWith(this._formGroup.status)),
      this._formGroup.ngSubmit,
    )
      .pipe(
        filter(() => {
          return this._formGroup.invalid && this._formGroup.submitted;
        }),
      )
      .subscribe(() => {
        if (this._formGroup.form.controls.general?.invalid) {
          this._generalSectionError$.next();
        }

        setTimeout(() => {
          this._scrollToTop();
        }, 150);
      });
  }

}
