import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { IFormField } from '@c-fields/common';


@Component({
  selector: 'app-form-section',
  templateUrl: './form-section.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormSectionComponent {

  @Input()
  public form: FormGroup;

  @Input()
  public icon: string;

  @Input()
  public name: string;

  @Input()
  public fields: IFormField[];

  @Input()
  public collapsable = false;

  public collapsed = true;

  public toggleCollapse(): void {
    if (!this.collapsable) {
      return;
    }

    this.collapsed = !this.collapsed;
  }
}
