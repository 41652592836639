/* eslint-disable */
export class Consent {

  id: string;
  title: string;
  iconSvg: string;
  html: string;
  value: boolean;
  type: string;

  constructor(data: Consent) {
    Object.assign(this, data);
  }

}
