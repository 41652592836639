import {
  createFieldInput,
  FieldInputType,
  IFieldInput,
  IFieldsData,
  IFormField,
  SimpleField,
} from '@c-fields/common';

import { FormControlBuilder } from '../../common/builders/form-control.builder';


export class LastNameField extends SimpleField implements IFormField {

  protected get _canAddEmptyFormControl(): boolean {
    return false;
  }

  public toJSON(): IFieldInput[] {
    return this._formArray.value
      .map((value: string, index: number) => {
        return createFieldInput<string>(FieldInputType.LastName, index, value);
      });
  }

  protected _buildFormControl(index: number): FormControlBuilder {
    const builder = super._buildFormControl(index);
    builder.addLastNameValidator();

    return builder;
  }

  protected _setInitialValues(values: IFieldsData): void {
    this._initialValues = [values.person.name.lastName];
  }
}
