import { Component, ElementRef, HostBinding, OnInit } from '@angular/core';

import { PopoverService } from './popover.service';


@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
})
export class PopoverComponent implements OnInit {

  constructor(
    public popoverService: PopoverService,
    private elementRef: ElementRef,
  ) { }

  ngOnInit(): void {
  }

  @HostBinding('style.top') get top() {
    return `${this.popoverService.top  }px`;
  }

  onPopoverClick(event: MouseEvent) {
    this.popoverService.removePopover();
  }

  getPositionOfArrow() {
    const marginCompensation = 20;
    const arrowWidthCompensation = 8;

    return ((this.popoverService.ending - this.popoverService.beginning) / 2)
      + this.popoverService.beginning - this.elementRef.nativeElement.getBoundingClientRect().left
      - marginCompensation - arrowWidthCompensation;

  }
}
