import { BaseStylesInterface } from '../../../libs/common/interfaces/base-styles.interface';


export class BaseStyleFactory {

  public static addOpacity(color: string, opacity: number): string {
    if (opacity <= 0 || opacity >= 100) {
      throw new Error('Opacity must be between 0 and 100');
    }
    if (color.includes(';')) {
      return color.replace(';', opacity.toString());
    }

    return color + opacity;
  }

  public static adaptBasicStyles(data: any): BaseStylesInterface {
    return {
      textDefault: data?.text_default,
      textLight: data?.text_light,
      primary: data?.primary_color,
      primaryBackground: data?.primary_background,
      secondary: data?.secondary,
      secondaryBackground: data?.secondary_background,
      box: data?.box,
      boxBackground: data?.box_background,
      warn: data?.warn,
      warnBackground: data?.warn_background,
      error: data?.error,
      errorBackground: data?.error_background,
      iconColor: data?.icon_color,
      pageBackground: data?.page_background,
      formBackground: data?.form_background,
    };
  }

  public static applyBaseStyles(base: BaseStylesInterface): void {
    if (base.textDefault) {
      document.body.style.setProperty('--text-default', base.textDefault);
    }

    if (base.textLight) {
      document.body.style.setProperty('--text-light', base.textLight);
    }

    if (base.primary) {
      document.body.style.setProperty('--primary', base.primary);
    }

    if (base.primaryBackground) {
      document.body.style.setProperty('--primary-background', base.primaryBackground);
    }

    if (base.secondary) {
      document.body.style.setProperty('--secondary', base.secondary);
    }

    if (base.secondaryBackground) {
      document.body.style.setProperty('--secondary-background', base.secondaryBackground);
    }

    if (base.box) {
      document.body.style.setProperty('--box', base.box);
    }

    if (base.boxBackground) {
      document.body.style.setProperty('--box-background', base.boxBackground);
    }

    if (base.iconColor) {
      document.body.style.setProperty('--icon-color', base.iconColor);
    }

    if (base.warn) {
      document.body.style.setProperty('--warn', base.warn);
    }

    if (base.warnBackground) {
      document.body.style.setProperty('--warn-background', base.warnBackground);
    }

    if (base.error) {
      document.body.style.setProperty('--error', base.error);
    }

    if (base.errorBackground) {
      document.body.style.setProperty('--error-background', base.errorBackground);
    }

    if (base.pageBackground) {
      document.body.style.setProperty('--page-background', base.pageBackground);
    }

    if (base.formBackground) {
      document.body.style.setProperty('--form-background', base.formBackground);
    }
  }
}
