import {
  IFieldConfig,
  IFieldFactory,
  IFieldsData,
  IFormField,
  Section,
  Sections,
} from '@c-fields/common';

import { createFieldFromConfig } from './create-fields-from-config';


/**
 * createSection takes Requested Field Configs and creates IFormField instances for them.
 * It uses factoriesMap object and in this object looking
 * for Field Type key (i.e. Email/Address/etc.)
 *
 */
export function createSections(
  configs: IFieldConfig[],
  factoriesMap: Record<string, IFieldFactory>,
  data: IFieldsData,
): Sections {
  const orderedFields = Object.keys(factoriesMap);

  return configs
    .filter((config: IFieldConfig) => {
      return !!factoriesMap[config.type] && config.section !== Section.Advertisement;
    })
    .sort((a: IFieldConfig, b: IFieldConfig) => {
      return orderedFields.indexOf(a.type) - orderedFields.indexOf(b.type);
    })
    .reduce((acc: {[key: string]: IFormField[]}, fieldConfig: IFieldConfig) => {
      if (!Array.isArray(acc[fieldConfig.section])) {
        acc[fieldConfig.section] = [];
      }

      const fieldFactory = factoriesMap[fieldConfig.type];

      const field = createFieldFromConfig(
        fieldConfig,
        fieldFactory,
        data,
      );

      acc[fieldConfig.section].push(field);

      return acc;
    }, {});
}
