import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormGroupDirective, NgControl, ValidationErrors } from '@angular/forms';


import { merge, Observable, ReplaySubject, startWith } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { FormDirective } from '@c-form';

import { FieldControl } from '../../directives/field-control.directive';
import { validationErrorsToMessage } from '../../helpers/validation-errors-to-message';


let uniqFieldID = 0;

@Component({
  // eslint-disable-next-line
  selector: 'form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormFieldComponent implements OnInit, AfterViewInit, OnDestroy {

  public error$: Observable<string>;

  @ContentChild(FieldControl, { static: true })
  private _fieldControl: FieldControl;

  @ContentChild(FieldControl, { static: true, read: NgControl })
  private _fieldNgControl: NgControl;

  @ContentChild(FieldControl, { read: ElementRef, static: true })
  private _ngControlElement: ElementRef;

  private readonly _cForm: FormDirective = inject(FormDirective);
  private readonly _cdRef = inject(ChangeDetectorRef);
  private readonly _controlID = `field-control-${uniqFieldID++}`;
  private readonly _destroy$ = new ReplaySubject<void>();

  public get fieldControl(): FieldControl {
    return this._fieldControl;
  }

  public get errors(): ValidationErrors | null {
    return this._fieldControl.ngControl.errors;
  }

  public get required(): boolean {
    return this._fieldControl.required;
  }

  public get controlID(): string {
    return this._controlID;
  }

  public get errorState$(): Observable<boolean> {
    return merge(
      this._fieldControl.statusChanges,
      this._form.ngSubmit,
    ).pipe(
      startWith(this._errorState),
      map(() => {
        return this._errorState;
      }),
    );
  }

  private get _form(): FormGroupDirective {
    return this._cForm.form;
  }

  private get _errorState(): boolean {
    const controlInvalid = this._fieldNgControl.invalid;
    const shouldShowError = this._fieldNgControl.dirty
      || this._fieldNgControl.touched
      || this._form.submitted;

    return controlInvalid && shouldShowError;
  }

  public ngOnInit(): void {
    if (!this._fieldControl) {
      throw new Error(`
        Can not find [fieldControl] directive.
        fieldInput should be applied to ControlValueAccessor:
        <form-field>
          <input fieldInput>
        </form-field>
      `);
    }

    this._setControlUniqID();
  }

  public ngAfterViewInit(): void {
    this._listenStatusChanges();
    this._listenControlErrors();

    // hack to fix CD glitch
    this._cdRef.markForCheck();
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  private _setControlUniqID(): void {
    this._ngControlElement.nativeElement.setAttribute('id', this.controlID);
  }

  /**
   * FormFieldControl should properly react on Control changes
   * ex.: validators updated or disabled state changed
   */
  private _listenStatusChanges(): void {
    this._fieldNgControl
      .statusChanges
      .pipe(
        takeUntil(this._destroy$),
      )
      .subscribe(() => {
        this._cdRef.detectChanges();
      });
  }

  private _listenControlErrors(): void {
    this.error$ = validationErrorsToMessage(this._fieldNgControl, () => this._cForm.errors);
  }

}
