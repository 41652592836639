<button (click)="showPopup()" class="btn btn-link btn-impressum c-btn-open" data-cy="impressum-btn">
  {{'info.buttons.show' | translate}}
</button>
<!--
  <button (click)="onSettingsClick()"
  *ngIf="storeService.setup && storeService.setup.features[pageType.Answering][featureType.Settings]"
  class="btn btn-link c-btn-open">
  {{'info.buttons.settings' | translate}}
</button>
-->

<div id="popup-container" [@show]="popupVisible ? 'visible' : 'hidden'"
  (click)="onPopupContainerClick($event)" class="c-popup-contanier">
  <div class="c-popup">
    <div class="c-header custom-header">
      <span (click)="setActive(tab)" *ngFor="let tab of tabs" [class.active]="tab.isActive"
            [attr.data-cy]="'tab-' + tab.cyTag"
            class="btn btn-primary">{{tab.titleCode | translate}}</span>
      <div class="c-spacer"></div>
      <button (click)="popupVisible=false" class="c-close-button">
        <div [appIcon]="'clear'" [size]="24" class="c-toggle-icon"></div>
      </button>
    </div>
    <div class="c-body">
      <div class="imp-logos">
        <img src="assets/img/complero-logo-4c.png">
        <p>{{ 'sender.offersYou' | translate }} {{storeService?.sender?.person?.company}}</p>
        <ng-container *ngIf="storeService?.sender?.person?.companyLogo">
          <img src="{{storeService?.sender?.person?.companyLogo}}">
        </ng-container>
      </div>
      <ng-container *ngFor="let tab of tabs">
        <div *ngIf="tab.fileName == 'imp.md' && tab.isActive">
          <p *ngIf="storeService?.sender?.person?.imprintLink">
            <a [href]="storeService?.sender?.person?.imprintLink" target="_blank">
              {{ 'sender.imprint' | translate }} {{storeService?.sender?.person?.company}}
            </a>
          </p>
          <br>
          <p>{{ 'complero.imprint' | translate }}</p>
        </div>
        <div *ngIf="tab.fileName == 'data.md' && tab.isActive">
          <p *ngIf="storeService?.sender?.person?.privacyLink">
            <a [href]="storeService?.sender?.person?.privacyLink" target="_blank">
              {{ 'sender.privacy' | translate }} {{storeService?.sender?.person?.company}}
            </a>
          </p>
          <br>
          <p>{{ 'complero.privacy' | translate }}</p>
        </div>
        <ng-container *ngIf="tab.isActive">
          <div class="c-loading-container" *ngIf="(fileLoader.state$ | async) === infoLoaderState.Load">
            <i [nzType]="'loading'" nz-icon></i> {{'global.loading' | translate}}...
          </div>
          <markdown class="c-content"
                    [attr.data-cy]="'content-' + tab.cyTag"
                    [attr.hidden]="(fileLoader.state$ | async) === infoLoaderState.Load || null"
                    [data]="fileLoader.data$ | async">
          </markdown>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
