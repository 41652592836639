import { Injectable } from '@angular/core';


import { ReplaySubject, Subject } from 'rxjs';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';

import { appConfig } from '../../../../app.config';


@Injectable({
  providedIn: 'root',
})
export class LangService {

  private _languages: string[] = appConfig.languages.available;

  private _overrides = new Map<string, unknown>();
  private _overridesChanged$ = new ReplaySubject<{lang: string, overrides: unknown}>();

  private _currentLang = appConfig.languages.default;
  private _currentLang$ = new Subject<string>();

  constructor(
    private _translateService: TranslateService,
  ) {
    this._translateService.setDefaultLang(this.current);
    this.setLanguage(this.current);
  }

  public get current(): string {
    return this._currentLang;
  }

  private set _current(value: string) {
    this._currentLang = value;
    this._currentLang$.next(this._currentLang);
  }

  public get languages(): string[] {
    return [...this._languages];
  }

  public setLanguage(language: string) {
    this._current = language;

    if (this._languages.includes(this.current)) {
      this._translateService.use(this.current)
        .pipe(
          switchMap(() => {
            return this._overridesChanged$;
          }),
          filter(({ lang }) => lang === this.current),
          tap(({ lang, overrides }) => {
            this._translateService
              .setTranslation(lang, overrides, true);
          }),
          takeUntil(this._currentLang$),
        )
        .subscribe();
    }
  }

  public setOverrides(lang: string, overrides: unknown) {
    if (this._overrides.has(lang)) {
      const existingOverride = this._overrides.get(lang);

      if (JSON.stringify(existingOverride) === JSON.stringify(overrides)) {
        return;
      }
    }

    this._overrides.set(lang, overrides);

    this._overridesChanged$.next({
      lang,
      overrides,
    });
  }
}

