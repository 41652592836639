import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgIf } from '@angular/common';

import { LangChangeEvent, TranslateModule, TranslateService } from '@ngx-translate/core';
import { SafeHtml } from '@common/pipes/safe.pipe';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


import { StoreService } from '../../app/core/services/store.service';
import { LegalContentInterface } from '../common/interfaces/legal-consent.interface';


@Component({
  selector: 'app-legal-element',
  templateUrl: './legal-element.component.html',
  styleUrls: ['./legal-element.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    TranslateModule,
    SafeHtml,
  ],
})
export class LegalElementComponent implements OnInit, OnDestroy {

  public language: string = this._translateService.getDefaultLang();
  public legal: LegalContentInterface;
  /* ----------------------------------------
   * Life-Cycles
   * -------------------------------------- */
  private _onDestroySubject = new Subject();
  // eslint-disable-next-line
  public onDestroy$ = this._onDestroySubject.asObservable();

  constructor(
    public storeService: StoreService,
    private _translateService: TranslateService,
  ) {
  }

  public ngOnInit(): void {
    this._getLegal();
    this._translateService.onLangChange.pipe(takeUntil(this.onDestroy$))
      .subscribe((params: LangChangeEvent) => {
        this.language = params.lang;
        this._getLegal();
      });
  }

  public ngOnDestroy() {
    this._onDestroySubject.next(true);
    this._onDestroySubject.complete();
  }

  private _getLegal() {
    // try the German version of the custom legal text as a fallback for now
    // TODO: Reevaluate whether using German as fallback language is desired
    this.legal = this.storeService.customLegal ?
      (this.storeService.customLegal[this.language] || this.storeService.customLegal.de) : null;
  }

}
