import { PersonInterface } from '../interfaces/person.interface';

import { InfoItem } from './Info-item';


export class Side {
  id: string;
  person: PersonInterface;
  message: string;
  workInfoItems: InfoItem[];
  homeInfoItems: InfoItem[];
  sharedInfoItems: InfoItem[];
  handler_id?: string;
  tags: string[];

  constructor(data: Side) {
    Object.assign(this, data);
  }

}
