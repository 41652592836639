import { Consent } from '../../classes/consent';
import { ConsentType } from '../../enums/consent.type';


export function filterAdvertisementConsents(
  consents: Consent[],
  options: { showDefaultConsents?: boolean, showReoConsents?: boolean },
): Consent[] {
  return consents.filter((consent: Consent) => {
    return (options.showDefaultConsents && consent.type === ConsentType.Default)
      || (options.showReoConsents && consent.type === ConsentType.Reo);
  });
}
