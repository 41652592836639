<div class="container">
  <div class="logo">
    <img src="assets/img/complero-logo-4c.png">
  </div>

  <div class="small-container">

    <img class="img-start" src="assets/img/robot.png">

    <div class="c-header">
      <h1>{{'home.header' | translate}}</h1>
    </div>

    <div class="c-text">
      <p>{{'home.text' | translate}}</p>
      <p>{{'home.text-2' | translate}}</p>
    </div>

    <br>

    <app-startsite-button></app-startsite-button>
  </div>

</div>
