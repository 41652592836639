<ng-container *ngIf="preferencesSectionVisible$ | async">
  <app-collapsable-section
    class="section"
    [title]="'preference.title' | translate"
    [collapsable]="setup.features[pageType][featureType.PreferenceToggle]"
    [icon]="'preferences'">
    <app-legal-element
      *ngIf="setup.features[pageType][featureType.LegalHtml] && storeService.customLegal && legalVisible$ | async"
      data-cy="legal-text">
    </app-legal-element>

    <app-consents-list [consents]="advertisementConsents"
                       [pageType]="pageType"
                       (changed)="consentsChanged($event)">
    </app-consents-list>

    <div class="c-legal-content" *ngIf="(legalFinePrint$ | async) as finePrint">
      <div [innerHTML]="finePrint | safe" class="c-legal-inner-content c-html"></div>
    </div>
  </app-collapsable-section>

  <ng-container *ngIf="setup.flow === 'flow_provinzial' && showProvinzialConsents">
    <app-collapsable-section
      class="section book-appointment"
      [title]="'preference.provinzial_title' | translate"
      [collapsable]="setup.features[pageType][featureType.PreferenceToggle]"
      [icon]="'calendar'">
      <app-consents-list [consents]="appointmentConsents"
                         [pageType]="pageType"
                         (changed)="consentsChanged($event)">
      </app-consents-list>
    </app-collapsable-section>
  </ng-container>
</ng-container>
