import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';

import {
  IField,
  IFormBuilder,
  IFormField,
  IRemoteConsent,
} from '@c-fields/common';
import { FormBuilder } from '@c-fields/builder';
import { timeoutCallback } from '@common/rxjs';

import { BehaviorSubject, EMPTY, finalize, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { FormDirective, IRemoteValidationError } from '@c-form';

import { StoreService } from '../../../../core/services/store.service';
import { ApiService } from '../../../../core/services/api.service';
import { PopoverService } from '../../../../shared/popover/popover.service';
import { PageType } from '../../../../../libs/common/enums/page.type';
import { AppNavService } from '../../../../core/services/app-nav.service';
import { LoaderService } from '../../../../../libs/loader';
import { environment } from '../../../../../environments/environment';
import {
  PersonSectionComponent,
} from '../../../../../libs/person-section/components/person-section';


@Component({
  selector: 'app-answering',
  templateUrl: './answering.component.html',
  styleUrls: ['./answering.component.scss'],
  providers: [
    PopoverService,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnsweringComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(FormDirective)
  public formDirective: FormDirective;

  @ViewChild(PersonSectionComponent)
  public _personSectionCmp: PersonSectionComponent;

  public form: FormGroup;
  public readonly pageType = PageType.Answering;
  // eslint-disable-next-line
  public serverErrorMessage = new BehaviorSubject<string>('');

  private _builder: IFormBuilder;
  private _loaderService: LoaderService = inject(LoaderService);
  private _activatedRoute: ActivatedRoute = inject(ActivatedRoute);

  constructor(
    public storeService: StoreService,
    private _apiService: ApiService,
    private _appNavService: AppNavService,
    private _popoverService: PopoverService,
    private _route: ActivatedRoute,
  ) {
  }

  public get generalFormGroup(): FormGroup {
    return this.form.get('general') as FormGroup;
  }

  public get privateFormGroup(): FormGroup {
    return this.form.get('private') as FormGroup;
  }

  public get workFormGroup(): FormGroup {
    return this.form.get('work') as FormGroup;
  }

  public get generalFields(): IFormField[] {
    return this._builder.sections.general;
  }

  public get privateFields(): IFormField[] {
    return this._builder.sections.private;
  }

  public get workFields(): IFormField[] {
    return this._builder.sections.work;
  }

  public get collapsableSections(): boolean {
    return !!this.privateFields && !!this.workFields;
  }

  public ngOnInit(): void {
    this._builder = new FormBuilder();
    this._builder.setConfigs(this.storeService.setup.fieldConfigs);
    this._builder.setData(this.storeService.data);
    this.form = this._builder.build();
  }

  public ngAfterViewInit(): void {
    this._popoverService.watchTargets('a', 'data-tooltip');
  }

  public ngOnDestroy(): void {
    this._popoverService.destroyWatchers();
  }

  public openGeneralSection(): void {
    this._personSectionCmp.enterEditMode();
  }

  public validate() {
    this.serverErrorMessage.next('');

    if (!this.form.valid) {
      return;
    }

    const data: IField[] = this._builder.toJSON();
    const consents: IRemoteConsent[] = this.storeService.preferences
      .map((c) => {
        return {
          id: String(c.id),
          type: String(c.type),
          given: c.value,
        };
      });

    this._loaderService.showLoader();

    this._apiService.sendAnswer(
      {
        fields: data,
        consents: consents,
      },
      {
        'code': this._activatedRoute.snapshot.queryParams.code,
        'source': this._activatedRoute.snapshot.queryParams.source,
      },
    )
      .pipe(
        timeoutCallback(() => {
          this._loaderService.showSubmitTimeout();
        }, environment.shortRequestTimeout),
        catchError((err: HttpErrorResponse) => {
          if (err.status === 400) {
            const remoteErrors = err.error.field_errors as IRemoteValidationError[];

            this.formDirective.setRemoteErrors(remoteErrors);
          } else if (err.status === 303) {
            const done = this._appNavService.redirectToExternalURL(err.error);

            if (!done) {
              return of(err);
            }
          } else {
            this.serverErrorMessage.next(err.error?.message);
          }

          return EMPTY;
        }),
        finalize(() => {
          this._loaderService.hideLoader();
        }),
      )
      .subscribe(() => {
        this._appNavService.goToNext(
          this.storeService.setup.sequence,
          this.pageType,
          this._route.snapshot.queryParams,
        );
      });
  }
}
