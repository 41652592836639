import { NgModule } from '@angular/core';

import { CFormModule } from '@c-form';

import { SharedModule } from '../../shared/shared.module';
import { AnsweringFormLayoutModule } from '../../../libs/answering-form-layout';
import { PersonSectionModule } from '../../../libs/person-section';
import { TopBarComponent } from '../../../libs/top-bar/top-bar.component';
import {
  ConsentsSectionComponent,
} from '../../../libs/consents-section/consents-section.component';

import { AnsweringComponent } from './components/answering/answering.component';


@NgModule({
  imports: [
    CFormModule,

    SharedModule,
    AnsweringFormLayoutModule,
    PersonSectionModule,
    TopBarComponent,
    ConsentsSectionComponent,
  ],
  declarations: [
    AnsweringComponent,
  ],
  exports: [
    AnsweringComponent,
  ],
})
export class AnsweringModule {

}
