import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';


import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

import { ActivatedRoute } from '@angular/router';

import { timeoutCallback } from '@common/rxjs';

import { catchError, share, take } from 'rxjs/operators';
import { of, forkJoin, Observable, finalize } from 'rxjs';

import { MainAdapter } from '../adapters/main.adapter';
import { SideAdapter } from '../adapters/side.adapter';
import { Consent } from '../../../libs/common/classes/consent';
import { environment } from '../../../environments/environment';
import { SetupAdapter } from '../adapters/setup.adapter';
import { BaseStyleFactory } from '../factories/base-styles.factory';
import { LangService } from '../modules/translation';
import { LoaderService } from '../../../libs/loader';

import { AppNavService } from './app-nav.service';
import { StoreService } from './store.service';


@Injectable()
export class ApiService {


  constructor(
    @Inject(DOCUMENT) private document: Document,
    private storeService: StoreService,
    private http: HttpClient,
    private _appNavService: AppNavService,
    private _langService: LangService,
    private _loaderService: LoaderService,
    private _route: ActivatedRoute,
  ) {}

  getAll(token: string, additionalURLParams?: { [key: string]: string }): Observable<any> {

    const headers: HttpHeaders = new HttpHeaders({
      'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
      'Pragma': 'no-cache',
    });

    if (this.storeService.initialOpen) {
      additionalURLParams = {
        ...additionalURLParams,
        initial: 'true',
      };

      this.storeService.initialOpen = false;
    }

    const data$ = this.http.get(
      `${environment.hostname}/api/v1/r/${token}/data`,
      {
        headers: headers,
        params: additionalURLParams,
      },
    ).pipe(catchError((err) => of(err)));

    const meta$ = this.http.get(
      `${environment.hostname}/api/v1/r/${token}/metadata`,
      {
        headers: headers,
        params: additionalURLParams,
      },
    ).pipe(catchError((err) => of(err)));

    this._loaderService.showLoader();
    const joinedObservable = forkJoin([
      data$,
      meta$,
    ])
      .pipe(
        share(),
        timeoutCallback(() => {
          this._loaderService.showReceiveTimeout();
        }, environment.shortRequestTimeout),
        finalize(() => {
          this._loaderService.hideLoader();
        }),
      );

    joinedObservable.subscribe((all) => {

      let data = all[0] as any;
      const meta = all[1] as any;

      if (!meta || meta instanceof HttpErrorResponse) {
        if (!window.location.href.includes('error')) {
          this._appNavService.goToErrorPage();

          return;
        }
      }

      this.storeService.setToken(token);
      const setupFromSetupBaseAndMeta = SetupAdapter.makeSetup(meta);
      this.storeService.setSetup(setupFromSetupBaseAndMeta);

      if ((!data || data instanceof HttpErrorResponse) && data?.status !== 403) {
        if (data?.status === 410) {
          this._appNavService.goToExpiredTokenPage();

          return;
        }

        this._appNavService.goToErrorPage();

        return;
      }

      /* ----------------------------------------
       * 🛠 Main data parser
       * -------------------------------------- */
      let adapted = { ...meta, ...data };
      try {
        adapted = MainAdapter.processData({ ...meta, ...data });
      } catch (e) {
        if (!window.location.href.includes('error')) {
          this._appNavService.goToErrorPage();
        }
      }

      /* ----------------------------------------
       * 🎨 Restyling
       * -------------------------------------- */
      BaseStyleFactory.applyBaseStyles(adapted.baseStyles);
      this.applyCustomStyles(adapted.customCSS);

      /* ----------------------------------------
       * 🗄️ Fullfill storage
       * -------------------------------------- */
      this.storeService.setAccount({ current: { name: data.company?.name || '' } });
      this.storeService.setSender(
        SideAdapter.processData(adapted.sender, setupFromSetupBaseAndMeta),
      );
      this.storeService.setReceiver(
        SideAdapter.processData(adapted.receiver, setupFromSetupBaseAndMeta),
      );
      this.storeService.initFields({ ...adapted.receiver, ...setupFromSetupBaseAndMeta });
      this.storeService.setPreferences(this.makeConsents(data));
      this.storeService.setCustomIcons(adapted.icons);
      this.storeService.setCustomLegal(adapted.customLegal);
      this.storeService.setCustomCopy(adapted.customCopy);
      this.storeService.setContractNumber(adapted.contractNumber);
      this.storeService.setRequestConfirmation(adapted.confirmationRequested);
      /* ----------------------------------------
       * ⛴ Navigation start
       * -------------------------------------- */
      this._appNavService.setFirstPageOfSequence(this.storeService.setup.sequence[0]);

      /* ----------------------------------------
       * 💬 Translation for custom "Copy"
       * -------------------------------------- */
      if (this.storeService.customCopy) {
        Object.keys(this.storeService.customCopy).forEach((language) => {
          this._langService.setOverrides(language, this.storeService.customCopy[language]);
        });
      }
    });

    return joinedObservable;
  }

  public sendAnswer(data: any, additionalURLParams?: { [key: string]: string }): Observable<any> {
    return this.http.post(
      `${environment.hostname}/api/v2/r/${this.storeService.token}/answer`,
      data,
      {
        params: additionalURLParams,
      },
    );

  }

  checkVerificationCode(token, code, source?: string) {
    return this.http.get(
      `${environment.hostname}/api/v1/r/${token}/verification`,
      {
        params: {
          code,
          source,
        },
      },
    );
  }

  trackVcard(token) {
    return this.http.get(`${environment.hostname}/api/v1/r/${token}/vcard_download`).pipe(take(1));
  }

  private makeConsents(data: {
    company: { advertisement_consent: { id: string, native_id?: string, icon?: string, subject: string, description: string, type: string }[] },
    contact: { advertisement_consents_given: string[] }
  }) {
    return (data.company?.advertisement_consent || []).map((item) => {
      return new Consent({
        id: item.native_id || item.id,
        type: item.type,
        iconSvg: item.icon || null,
        title: this.addNoBreakingWordsOnHyphen(item.subject),
        html: this.addNoBreakingWordsOnHyphen(item.description),
        value: !!(data.contact?.advertisement_consents_given || []).find((id) => id === item.native_id || id === item.id),
      });
    });
  }

  private addNoBreakingWordsOnHyphen(str: string) {
    function revertReplacer(match) {
      return match.replace(/-\u2060/gim, '-');
    }

    str = str.replace(/-/gim, '-\u2060');

    return str.replace(/<[^<>]*>/gi, revertReplacer);
  }

  saveAvatar(token: string, newAvatarBase64: string = null) {
    const base64 = newAvatarBase64.replace('data:image/png;base64,', '');

    return this.http.post(`${environment.hostname}/api/v1/r/${token}/avatar`, { body: base64 }).pipe(take(1));
  }

  private applyCustomStyles(css: string): void {
    const head = this.document.getElementsByTagName('head')[0];
    const style = this.document.createElement('style');
    style.innerHTML = css;
    head.appendChild(style);
  }
}


