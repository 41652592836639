import { IFieldInput } from '../interfaces/field-input.interface';
import { FieldInputType } from '../enums/input-type.enum';


/**
 * This helper just does simple creation of the FieldInput
 *
 * In the future, we can also implement here possible logic for default value for example
 */
export function createFieldInput<T = any>(
  type: FieldInputType,
  order: number,
  value: T,
): IFieldInput<T> {
  return {
    type,
    order,
    value: value,
  };
}
