/* eslint-disable */
import { PageType } from '../../../../libs/common/enums/page.type';
import { FieldType } from '../../../../libs/common/enums/field.type';
import { FeatureType } from '../../../../libs/common/enums/feature.type';
import { SetupInterface } from '../../../../libs/common/interfaces/setup.interface';
import { VerificationType } from '../../../../libs/common/enums/verification-type.enum';


export const flow2FAACompactSetup: SetupInterface = {
  flow: 'flow_2fa_a_compact',
  company: 'standard',
  sequence: [
    PageType.Answering,
    PageType.Sharing,
  ],
  fields: {
    [PageType.Answering]: {},
    [PageType.Deleting]: {
      [FieldType.DeleteOption1]: true,
      [FieldType.DeleteOption2]: true,
      [FieldType.DeleteOption3]: true,
      [FieldType.DeleteOption4]: true,
    },
  },
  features: {
    [PageType.Answering]: {
      [FeatureType.SenderMessage]: true,
      [FeatureType.SenderMessageCasual]: false,
      [FeatureType.Person]: true,
      [FeatureType.ReceiverMessage]: false,
      [FeatureType.ReceiverMessageCasual]: false,
      [FeatureType.WorkFolder]: true,
      [FeatureType.WorkFolderOpen]: false,
      [FeatureType.HomeFolder]: true,
      [FeatureType.HomeFolderOpen]: true,
      [FeatureType.LegalHtml]: true,
      [FeatureType.Preferences]: false,
      [FeatureType.PreferenceMessage]: true,
      [FeatureType.PreferenceToggle]: true,
      [FeatureType.PreferenceOpen]: true,
      [FeatureType.PreferenceColored]: false,
      [FeatureType.NextTitleForSendButton]: false,
      [FeatureType.Settings]: false,
      [FeatureType.ShowDefaultConsents]: true,
    },
    [PageType.Sharing]: {
      [FeatureType.SharingMessage]: true,
      [FeatureType.SharingMessageCasual]: false,
      [FeatureType.SharingContactList]: true,
      [FeatureType.SharingVcardLater]: false,
      [FeatureType.SharingVcard]: false,
    },
    [PageType.DOBAuthentication]: {
      [FeatureType.TopBar]: false,
    },
  },
  limits: {
    [PageType.Answering]: {},
  },
  readOnly: {
    [PageType.Answering]: {},
  },
  required: {
    [PageType.Answering]: {},
  },
  verificationType: VerificationType.None,
  includeEmployee: true,
  showTopbar: true,
  showContractNumber: false,
};
