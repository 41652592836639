import { PageType } from '../../../../libs/common/enums/page.type';
import { SetupInterface } from '../../../../libs/common/interfaces/setup.interface';
import { FeatureType } from '../../../../libs/common/enums/feature.type';
import { LoaderType } from '../../../../libs/common/enums/loader.type';
import { VerificationType } from '../../../../libs/common/enums/verification-type.enum';


export const flowFreeNetSetup: SetupInterface = {
  flow: 'flow_freenet',
  company: 'freenet',
  loaderType: LoaderType.Circle,
  sequence: [
    PageType.Answering,
    PageType.Confirmation,
  ],
  fields: {
    [PageType.Answering]: {},
    [PageType.Confirmation]: {},
  },
  features: {
    [PageType.Answering]: {
      [FeatureType.TopBar]: true,
      [FeatureType.Person]: true,
      [FeatureType.InlinePersonInfo]: true,
      [FeatureType.SenderMessage]: true,
      [FeatureType.AppInfo]: true,
      [FeatureType.HomeFolder]: true,
      [FeatureType.HomeFolderOpen]: true,
      [FeatureType.WorkFolderOpen]: true,
      [FeatureType.PreferenceToggle]: false,
      [FeatureType.PreferenceOpen]: true,
      [FeatureType.Preferences]: true,
      [FeatureType.ShowAddressTitle]: true,
      [FeatureType.AddressInputTopIcon]: true,
      [FeatureType.ShowInfoInputsByDefault]: true,
      [FeatureType.CheckboxInlinePreferenceText]: true,
      [FeatureType.ShowDefaultConsents]: true,
      [FeatureType.ShowReoConsents]: true,
      [FeatureType.ShowAddressExtra3]: false,
      [FeatureType.LegalHtml]: true,
    },
    [PageType.Confirmation]: {},
    [PageType.DOBAuthentication]: {
      [FeatureType.TopBar]: true,
      [FeatureType.SenderMessage]: true,
      [FeatureType.AppInfo]: true,
    },
  },
  limits: {
    [PageType.Answering]: {},
    [PageType.Sharing]: {},
  },
  readOnly: {
    [PageType.Answering]: {},
    [PageType.Sharing]: {},
  },
  required: {
    [PageType.Answering]: {},
    [PageType.Sharing]: {},
  },
  verificationType: VerificationType.None,
  includeEmployee: true,
  showTopbar: true,
  showContractNumber: true,
};
