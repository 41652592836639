import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { take } from 'rxjs/operators';

import { AppNavService } from '../../core/services/app-nav.service';
import { StoreService } from '../../core/services/store.service';


@Component({
  template: '',
})
export class RedirectToFirstInSequenceComponent {
  constructor(
    private appNavService: AppNavService,
    private storeService: StoreService,
    private route: ActivatedRoute,
  ) {
    this.storeService.setup$
      .pipe(take(1))
      .subscribe((setup) => {
        this.appNavService.goToPageType(setup.sequence[0], this.route.snapshot.queryParams);
      });
  }
}
