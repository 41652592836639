<ng-container [formGroup]="form">
  <ng-container formArrayName="salutation" *ngIf="form.get('salutation')">
    <form-field-wrapper>
      <form-field>
        <field-label>{{'labelTexts.salutation-alt' | translate}}</field-label>

        <nz-select fieldInput
                   autocomplete="off"
                   name="salutation"
                   nzShowSearch
                   formControlName="0"
                   class="form-control c-form-control custom-input c-select"
                   [nzPlaceHolder]="'receiver.placeholders.salutation-alt' | translate">
          <nz-option *ngFor="let option of salutationTranslations"
                     [nzValue]="option"
                     [nzLabel]="(!!option ? ('salutations.' + option | translate) : '')">
          </nz-option>
        </nz-select>
      </form-field>
    </form-field-wrapper>
  </ng-container>

  <ng-container formArrayName="title" *ngIf="form.get('title')">
    <form-field-wrapper>
      <form-field>
        <field-label>{{'labelTexts.title' | translate}}</field-label>

        <nz-select fieldInput
                   formControlName="0"
                   autocomplete="off"
                   name="title"
                   nzShowSearch
                   class="form-control c-form-control custom-input c-select"
                   nzPlaceHolder="{{'receiver.placeholders.title'|translate}}">
          <nz-option *ngFor="let option of titleTranslations"
                     [nzValue]="option"
                     [nzLabel]="(!!option ? ('titles.' + option | translate): '')">
          </nz-option>
        </nz-select>
      </form-field>
    </form-field-wrapper>
  </ng-container>

  <ng-container formArrayName="first_name" *ngIf="form.get('first_name')">
    <form-field-wrapper>
      <form-field>
        <field-label>{{'labelTexts.firstName' | translate}}</field-label>

        <input fieldInput
               formControlName="0"
               name="first_name"
               class="form-control c-input"
               [placeholder]="'receiver.placeholders.firstName' | translate">
      </form-field>
    </form-field-wrapper>
  </ng-container>

  <ng-container formArrayName="last_name" *ngIf="form.get('last_name')">
    <form-field-wrapper>
      <form-field>
        <field-label>{{'labelTexts.lastName' | translate}}</field-label>

        <input fieldInput
               formControlName="0"
               name="last_name"
               class="form-control c-input"
               [placeholder]="'receiver.placeholders.lastName' | translate">
      </form-field>
    </form-field-wrapper>
  </ng-container>

</ng-container>
