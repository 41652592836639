import { ControlContainer } from '@angular/forms';


export function getControlContainerName(controlContainer: ControlContainer): string {
  const parent = controlContainer.control.parent;

  if (!!parent) {
    const parentControls: [string, unknown][] = Object.entries(parent.controls);

    const controlIndex = parentControls.findIndex(([_, control]) => {
      return control === controlContainer.control;
    });

    if (controlIndex === -1) {
      throw new Error('Not able to find control name');
    }

    return String(parentControls[controlIndex][0]);
  }

  return '';
}
