import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { DOCUMENT } from '@angular/common';

import { Observable, of } from 'rxjs';

import { ApiService } from '../services/api.service';
import { StoreService } from '../services/store.service';
import { environment } from '../../../environments/environment';


@Injectable({ providedIn: 'root' })
export class SetupResolver  {
  constructor(
    private api: ApiService,
    private storeService: StoreService,
    @Inject(DOCUMENT)
    private _document: Document,
  ) { }

  resolve(
    route: ActivatedRouteSnapshot,
  ): Observable<any> | Promise<any> | any {
    const token = route.params.token;
    // we don't want to take care about UUID different versions
    // and do not want to validate UUID in the best way for now
    // just naive assumption that token is shortlink code
    const isShortlinkCode = token.length >= 5 && token.length <= 10;

    if (isShortlinkCode) {
      const { defaultView } = this._document;

      if (!defaultView) {
        throw new Error('Window is not available');
      }

      const window = (defaultView as Window);
      window.location.href = `${environment.shortlinkUrl}${token}`;

      return of(true);
    }

    return this.api.getAll(token, {
      'source': route.queryParams.source,
      'code': route.queryParams.code,
      'batch': route.queryParams.batch,
    });


  }
}

