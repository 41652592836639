export function arrayIncludesSubset(array: unknown | unknown[], subset: unknown | unknown[]): boolean {
  if (!Array.isArray(array) || array.length === 0) {
    return false;
  }

  const subsetArr: unknown[] = Array.isArray(subset)
    ? subset
    : [subset];

  return subsetArr.every((item) => {
    return array.indexOf(item) !== -1;
  });
}
