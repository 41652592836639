import { Component, inject } from '@angular/core';
import { NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';

import { StoreService } from '../../app/core/services/store.service';
import { TelekomTopBarComponent } from '../company-specific/telekom/top-bar/top-bar.component';
import { TopBarDefaultComponent } from '../company-specific/default/top-bar/top-bar.component';


@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
  standalone: true,
  imports: [
    NgSwitch,
    NgSwitchCase,
    TelekomTopBarComponent,
    NgSwitchDefault,
    TopBarDefaultComponent,
  ],
})
export class TopBarComponent {

  private _storeService = inject(StoreService);

  public get company(): string | null {
    return this._storeService.setup?.company;
  }

  public get contractNumber(): string | null {
    return this._storeService.contractNumber || null;
  }
}
