import { DomSanitizer } from '@angular/platform-browser';
import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'safe',
  standalone: true,
})
export class SafeHtml implements PipeTransform {
  constructor(private _sanitizer: DomSanitizer) { }

  public transform(html: string) {
    return this._sanitizer.bypassSecurityTrustHtml(html);
  }
}
