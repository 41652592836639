import { Field } from '@c-fields/common';

import {
  IRemoteValidationError,
  IRemoteValidationInputError,
} from '../interfaces/remote-validation-error.interface';
import { IExternalValidationError } from '../interfaces/external-validation-error.interface';
import { FIELDS } from '../../c-fields/builder/fields-list';


export function processRemoteValidationErrors(
  validationErrors: IRemoteValidationError[],
): IExternalValidationError[] {
  return validationErrors
    .reduce((acc: IExternalValidationError[], error: IRemoteValidationError) => {
      if (!error.inputErrors || error.inputErrors.length === 0) {
        const externalError = buildSectionValidationError(error);
        acc.push(externalError);

        return acc;
      }

      error.inputErrors
        .forEach((iError) => {
          const externalError = buildControlValidationError(error, iError);
          acc.push(externalError);
        });

      return acc;
    }, []);
}

function buildSectionValidationError(error: IRemoteValidationError): IExternalValidationError {
  const path = `${error.section}.${error.type}`;

  return {
    code: error.code.toString(),
    path: path,
    message: error.message,
  };
}

function buildControlValidationError(
  error: IRemoteValidationError,
  iError: IRemoteValidationInputError,
): IExternalValidationError {
  let fieldPath = `${error.section}.${error.type}.${iError.order}`;
  const fieldFactory = FIELDS[error.type as Field];

  if (fieldFactory.isComplexField) {
    fieldPath += `.${iError.type}`;
  }

  return {
    code: iError.code.toString(),
    path: fieldPath,
    message: iError.message,
  };
}
