import { InfoItem } from './Info-item';


export class EventDate extends InfoItem {

  dd: string;
  mm: string;
  yyyy: string;

  constructor(data: EventDate) {
    super(data);
    Object.assign(this, data);
  }

  isEmpty?() {
    return [
      this.dd,
      this.mm,
      this.yyyy,
    ].every((value) => (!value || String(value).trim() === ''));
  }

}


