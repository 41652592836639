import { NgModule } from '@angular/core';
import { AsyncPipe, NgIf } from '@angular/common';

import { IconDirective } from '@common/directives';
import { TranslateModule } from '@ngx-translate/core';

import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

import { FieldLabelDirective } from './directives/field-label.directive';
import { FieldInputDirective } from './directives/field-input.directive';
import { FormFieldWrapperComponent } from './components/form-field-wrapper';
import { FormFieldComponent } from './components/form-field';


@NgModule({
  declarations: [
    FormFieldWrapperComponent,
    FieldLabelDirective,
    FormFieldComponent,
    FieldInputDirective,
  ],
  exports: [
    FormFieldWrapperComponent,
    FormFieldComponent,
    FieldLabelDirective,
    FieldInputDirective,
  ],
  imports: [
    IconDirective,
    NgIf,
    NzToolTipModule,
    TranslateModule,
    AsyncPipe,
  ],
})
export class FormFieldModule {
}
