import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { DecimalPipe, NgForOf } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';
import { isDate, isDateValid } from '@common/helpers/date';
import { padWithZero } from '@common/helpers/pad-with-zero';

import { CFormModule } from '@c-form';

import { FormFieldModule } from '../../form-field';
import { BaseCodeInputComponent, ICodeInputConfig } from '../../code-input/base';


@Component({
  selector: 'app-birthday-form-item',
  templateUrl: './birthday-form-item.component.html',
  styleUrls: [
    '../../code-input/base/base-code-input.component.scss',
    './birthday-form-item.component.scss',
  ],
  standalone: true,
  imports: [
    TranslateModule,
    FormsModule,
    CFormModule,
    FormFieldModule,
    NgForOf,
    DecimalPipe,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BirthdayFormItemComponent),
      multi: true,
    },
  ],
})
export class BirthdayFormItemComponent extends BaseCodeInputComponent
  implements ControlValueAccessor {

  public model: string[] = ['', '', ''];
  public items: ICodeInputConfig[] = [
    {
      labelLangKey: null,
      inputLangKey: null,
      placeholder: 'DD',
      maxLength: 2,
      name: 'b-day',
      regex: /^\d+$/,
    },
    {
      labelLangKey: null,
      inputLangKey: null,
      placeholder: 'MM',
      maxLength: 2,
      name: 'b-month',
      regex: /^\d+$/,
    },
    {
      labelLangKey: null,
      inputLangKey: null,
      placeholder: 'YYYY',
      maxLength: 4,
      name: 'b-year',
      regex: /^\d+$/,
    },
  ];

  private _onChange: (value: Date) => void;
  private _ouTouched: () => void;
  private _disabled = false;

  public get disabled(): boolean {
    return this._disabled;
  }

  public writeValue(value: Date): void {
    if (isDate(value) && isDateValid(value)) {
      const year = String(value.getFullYear());
      let day = String(value.getDate());
      let month = String(value.getMonth() + 1);


      this.model = [
        day,
        month,
        year,
      ];

      this.padInputsWithZero();
    } else {
      this.model = ['', '', ''];
    }
  }

  public registerOnChange(fn: (value: Date) => void): void {
    this._onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this._ouTouched = fn;
  }

  public setDisabledState(isDisabled: boolean) {
    this._disabled = isDisabled;
  }

  public changed(): void {
    const day = padWithZero(this.model[0], 1);
    const month = padWithZero(this.model[1], 1);
    const year = padWithZero(this.model[2], 3);

    if (day.length === 2 && month.length === 2 && year.length === 4) {
      let date = new Date(Date.parse(`${year}-${month}-${day}`));

      this._onChange(date);
    } else if (day || month || year) {
      this._onChange(new Date('Invalid Date'));
    } else {
      this._onChange(null);
    }
  }

  public blur(): void {
    this.padInputsWithZero();
    this._ouTouched();
  }

  public padInputsWithZero(): void {
    this.model[0] = padWithZero(this.model[0], 1);
    this.model[1] = padWithZero(this.model[1], 1);
    this.model[2] = padWithZero(this.model[2], 3);
  }
}
