export class CustomIconsFactory {
  static sizePx: string = '20';

  public static parseSvg(svg: string): string {
    if (!svg || svg.length === 0) {
      return '';
    }

    svg = this.prependHeightAndWidthIfNeeded(svg);
    svg = this.setIconSize(svg);

    return svg;
  }

  public static prependHeightAndWidthIfNeeded(svg: string): string {
    if (!svg.includes('width=')) {
      svg = svg.replace('<svg ', `<svg width="${this.sizePx}"`);
    }
    if (!svg.includes('height=')) {
      svg = svg.replace('<svg ', `<svg height="${this.sizePx}"`);
    }

    return svg;
  }

  public static setIconSize(svg: string): string {
    svg = svg.replace(/width="\d*"/i, `width="${this.sizePx}" `);
    svg = svg.replace(/height="\d*"/i, `height="${this.sizePx}" `);

    return svg;
  }
}
