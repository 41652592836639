import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';

import { BirthdayVerificationComponent } from '../../../libs/code-input/birthday-verification';

import { BirthdayComponent } from './birthday/birthday.component';


@NgModule({
  declarations: [BirthdayComponent],
  exports: [
    BirthdayComponent,
  ],
  imports: [
    SharedModule,
    BirthdayVerificationComponent,
  ],
})
export class BirthdayModule { }
