import { InfoItem } from './Info-item';


export class Phone extends InfoItem {

  // phone: string;
  // code: string;
  value: string;
  isMobile?: boolean;

  constructor(data: Phone) {
    super(data);
    Object.assign(this, data);
  }

  isEmpty?() {
    return !this.value || this.value.trim() === '';
  }

}


