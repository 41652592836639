export enum InfoItemType {
  /* ----------------------------------------
   * (hbw) Note! Value is not important because it's not used directly
   * -------------------------------------- */
  EVENT = 'event',
  EMAIL = 'email',
  PHONE = 'phone',
  MOBILE = 'mobile',
  FAX = 'fax',
  ADDRESS = 'address',
  FULL_ADDRESS = 'full_address',
  URL = 'url',
  ORG = 'org',
}

export class InfoItemTypeCheck {
  public static isPhone(type: InfoItemType): boolean {
    return type === InfoItemType.PHONE || type === InfoItemType.MOBILE;
  }
}
