<ng-container *ngIf="firstLine">
  <div class="top-bar-first-line"
       [class.single]="!secondLine"
       data-cy="topbar-line-one">
    {{ firstLine }}
  </div>
</ng-container>

<ng-container *ngIf="secondLine">
  <div class="top-bar-second-line" data-cy="topbar-line-two">
    {{ 'topbar.contract' | translate }} {{ secondLine }}
  </div>
</ng-container>
