export enum Field {
  Email   = 'email',
  Address = 'full_address',
  FirstName = 'first_name',
  LastName = 'last_name',
  Birthday = 'birthday',
  Mobile = 'mobile',
  Phone = 'phone',
  Salutation = 'salutation',
  Title = 'title',
}
