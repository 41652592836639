import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';

import { VerificationType } from '@common/enums';
import { SetupInterface } from '@common/interfaces/setup.interface';

import { map, take } from 'rxjs/operators';

import { AppNavService } from '../services/app-nav.service';
import { StoreService } from '../services/store.service';

// GuardResolver means that it behaves like Guard, but implemented as Resolver
// by the reason of currently implemented DataFlow
// and the fact that in case it will be Guard – main resolver for Setup won't be called
export const verificationGuardResolver: ResolveFn<void> = (route: ActivatedRouteSnapshot) => {
  return verificationFn(route);
};

export const verificationFn = (
  route: ActivatedRouteSnapshot,
  storeService = inject(StoreService),
  appNavService = inject(AppNavService),
) => {
  return storeService.setup$
    .pipe(
      take(1),
      map((setup: SetupInterface) => {
        // If the current flow requires two-factor authorization
        // and no code is present as an URL param we redirect to the 2FA page
        const authRequired = setup?.verificationType === VerificationType.Birthday
          || setup?.verificationType === VerificationType.ZipCode;

        if (authRequired && !route.queryParams.code) {
          appNavService.goToVerification(route.queryParams);
        }

        return;
      }),
    );
};
