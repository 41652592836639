import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';


@Component({
  selector: 'app-person-section-edit',
  templateUrl: './person-section-edit.component.html',
  styleUrls: ['./person-section-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PersonSectionEditComponent {

  @Input()
  public form: FormGroup;

  public salutationTranslations: string[] = [
    '',
    'mr',
    'ms',
    'spouses',
    'notSpecified',
  ];

  public titleTranslations: string[] = [
    '',
    'dr',
    'prof',
    'profDr',
  ];
}
