import { InfoItem } from './Info-item';


export class Fax extends InfoItem {

  value: string;

  constructor(data: Fax) {
    super(data);
    Object.assign(this, data);
  }

  isEmpty?() {
    return !this.value || this.value.trim() === '';
  }

}


