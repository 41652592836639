import { IFieldRemoteConfig } from '../../interfaces/field-remote-config.interface';


export class RemoteFieldConfigBuilder {
  private _config: IFieldRemoteConfig;

  constructor() {
    this.reset();
  }

  public reset(): void {
    this._config = {
      section: null,
      type: null,
      required: false,
      readonly: false,
      limit: 1,
    };
  }

  public makeRequired(): void {
    this._config.required = true;
  }

  public makeReadonly(): void {
    this._config.readonly = true;
  }

  public setLimit(limit: number): void {
    this._config.limit = limit;
  }

  public setSection(section: string): void {
    this._config.section = section;
  }

  public setType(type: string): void {
    this._config.type = type;
  }

  public build(): IFieldRemoteConfig {
    const config = { ...this._config };
    this.reset();

    return config;
  }

}
