import { InfoItem } from './Info-item';


export class Url extends InfoItem {

  url: string;

  constructor(data: Url) {
    super(data);
    Object.assign(this, data);
  }

  isEmpty?() {
    return !this.url || this.url.trim() === '';
  }

}


