// eslint-disable-next-line
export const ERROR_MESSAGES: { [key: string]: string } = {
  // internal web app
  required: 'validation.required',
  email: 'validation.email',
  birthday: 'validation.Birthday',
  houseNumber: 'validation.houseValidation',
  zipCode: 'validation.ZipCode',
  phoneNumber: 'validation.phoneValidation',
  mobileNumber: 'validation.mobileValidation',
  firstName: 'validation.first_name',
  lastName: 'validation.last_name',
  // Email
  1: 'validation.required',
  2: 'validation.emailImplausible',
  3: 'validation.email',
  4: 'validation.emailOverLimit',
  5: 'validation.required',
  6: 'validation.emailImplausible',
  7: 'validation.email',
  // Birthday
  10: 'validation.required',
  11: 'validation.birthdayOverLimit',
  12: 'validation.Birthday',
  13: 'validation.required',
  14: 'validation.Birthday',
  // First Name
  20: 'validation.required',
  21: 'validation.first_name',
  22: 'validation.first_nameOverLimit',
  23: 'validation.required',
  24: 'validation.first_name',
  // Last Name
  30: 'validation.required',
  31: 'validation.last_name',
  32: 'validation.last_nameOverLimit',
  33: 'validation.required',
  34: 'validation.last_name',
  // Mobile
  40: 'validation.required',
  41: 'validation.mobileValidation',
  42: 'validation.mobileOverLimit',
  43: 'validation.required',
  44: 'validation.mobileValidation',
  // Phone
  50: 'validation.required',
  51: 'validation.phoneValidation',
  52: 'validation.phoneOverLimit',
  53: 'validation.required',
  54: 'validation.phoneValidation',
  // Fax
  60: 'validation.required',
  61: 'validation.faxValidation',
  62: 'validation.faxOverLimit',
  63: 'validation.required',
  64: 'validation.faxValidation',
  // Profile pic
  70: 'validation.required',
  71: 'validation.profilePicOverLimit',
  72: 'validation.required',
  // Title
  80: 'validation.required',
  81: 'validation.titleOverLimit',
  82: 'validation.required',
  // Title
  90: 'validation.required',
  91: 'validation.salutationOverLimit',
  92: 'validation.required',
  // Address
  100: 'validation.required',
  101: 'validation.addressValidation',
  102: 'validation.addressOverLimit',
  103: 'validation.required',
  104: 'validation.required',
  105: 'validation.required',
  106: 'validation.required',
  107: 'validation.required',
  108: 'validation.ZipCode',
};
