import {
  createFieldInput,
  FieldInputType,
  IFieldInput,
  IFieldsData,
  IFormField,
  Section,
  SimpleField,
} from '@c-fields/common';

import { FormControlBuilder } from '../../common/builders/form-control.builder';


export class EmailField extends SimpleField implements IFormField<string> {

  public toJSON(): IFieldInput[] {
    return this._formArray.value
      .filter((value) => !this._isValueEmpty(value))
      .map((value: string, index: number) => {
        return createFieldInput<string>(FieldInputType.Email, index, value);
      });
  }

  protected _buildFormControl(index: number): FormControlBuilder {

    const builder = super._buildFormControl(index);

    builder.addEmailValidator();
    builder.addEmailDomainValidator();

    return builder;
  }


  protected _setInitialValues(values: IFieldsData): void {
    switch (this._config.section) {
      case Section.Private: {
        this._initialValues = values?.home?.emails || [];

        break;
      }
      case Section.Work: {
        this._initialValues = values?.work?.emails || [];

        break;
      }
    }
  }

}
