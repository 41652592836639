import { padStart } from './pad-start';


export function padWithZero(value: string, num: number): string {
  if (value.length === 1) {
    return padStart(value, '0', num);
  }

  return value;
}
