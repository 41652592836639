import { IFormControlPath } from '../interfaces/form-control-path.interface';

// eslint-disable-next-line
export const FORM_CONTROL_PATH_INIT_ERROR
  = new Error('formControlPath path() called before setPath()');


/**
 * Class contains implementation how to build Field path
 */
export class FormControlPath implements IFormControlPath{

  protected _parent: FormControlPath | undefined;
  protected _path = '';

  private _initialized = false;

  constructor(parent: FormControlPath | undefined) {
    this._parent = parent;
  }

  public get path(): string {
    // if (!this._initialized) {
    // throw FORM_CONTROL_PATH_INIT_ERROR;
    // }

    return this._path;
  }

  public set(name: string): void {
    this._path = this._parent?.path || '';

    this._initialized = true;

    if (!!this.path) {
      this._path = `${this.path}.${name}`;
    } else {
      this._path = name;
    }
  }

  public skip(): void {
    this._path = this._parent?.path || '';

    this._initialized = true;
  }
}
