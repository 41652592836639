<ng-container *ngFor="let consent of consents">
  <div [ngClass]="{'c-colored': features[featureType.PreferenceColored]}"
       class="c-item c-item-no-bottom">
    <div class="c-icon"></div>
    <div class="c-entry">
      <div class="c-description">
        <div *ngIf="consent.iconSvg" class="c-consent-icon" [innerHTML]="consent.iconSvg | safe"></div>
        <div class="c-title" data-cy="consent-title">{{consent.title}}</div>
      </div>
    </div>
  </div>

  <div [ngClass]="{'c-colored': features[featureType.PreferenceColored]}"
       class="c-item c-item-no-top">
    <div class="c-icon">
      <ng-container *ngIf="!features[featureType.CheckboxInlinePreferenceText]">
        <p-checkbox [checked]="consent.value"
                    (change)="change(consent)"
                    class="custom-checkbox"
                    shape="curve"
                    animation="pulse"
                    color="primary"
                    enableFocus="true"
                    data-cy="consent-checkbox">
          <svg p-svg style="transform: scale(0.7);" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
               viewBox="0 0 24 24" aria-hidden="true">
            <g fill="currentColor">
              <g>
                <path
                  d="M22.4 5.05c-.3-.3-.75-.3-1.05 0L9 17.4l-5.9-5.9c-.3-.3-.75-.3-1.05 0s-.3.75 0 1.05L9 19.5 22.4 6.1c.3-.3.3-.75 0-1.05z"
                  fill-rule="evenodd"></path>
              </g>
            </g>
          </svg>
        </p-checkbox>
      </ng-container>
    </div>

    <div class="c-entry">
      <div class="c-description">
        <ng-container *ngIf="features[featureType.CheckboxInlinePreferenceText]">
          <div class="c-inline-checkbox-container">
            <p-checkbox (change)="change(consent)"
                        [checked]="consent.value"
                        shape="curve"

                        animation="pulse"
                        color="primary"
                        enableFocus="true"
                        class="c-inline-checkbox custom-checkbox"
                        data-cy="consent-checkbox">
              <svg p-svg style="transform: scale(0.7);" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                   viewBox="0 0 24 24" aria-hidden="true">
                <g fill="currentColor">
                  <g>
                    <path
                      d="M22.4 5.05c-.3-.3-.75-.3-1.05 0L9 17.4l-5.9-5.9c-.3-.3-.75-.3-1.05 0s-.3.75 0 1.05L9 19.5 22.4 6.1c.3-.3.3-.75 0-1.05z"
                      fill-rule="evenodd"></path>
                  </g>
                </g>
              </svg>
            </p-checkbox>
          </div>
        </ng-container>

        <div class="c-subtitle custom-subtitle" data-cy="consent-text"
             [innerHTML]="consent.html | safe"></div>
      </div>
    </div>
  </div>
</ng-container>
