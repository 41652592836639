<div class="c-person-section-container">
  <ng-container *ngIf="editMode$ | async; else previewMode">
    <app-person-section-edit [form]="form">
    </app-person-section-edit>
  </ng-container>
  <ng-template #previewMode>
    <app-person-section-preview
      [firstName]="firstNameControl?.value"
      [lastName]="lastNameControl?.value">
    </app-person-section-preview>

    <ng-container *ngIf="editableSection$ | async">
      <div [appIcon]="'edit'" class="c-edit-icon" data-cy="edit-personal" (click)="enterEditMode()"></div>
    </ng-container>
  </ng-template>
</div>
