<div class="c-form-field-icon">
  <ng-container *ngIf="icon">
    <span [appIcon]="icon" [size]="20" data-cy="field-icon"></span>
  </ng-container>
</div>

<div class="c-form-field-control-wrapper">
  <ng-content></ng-content>
</div>

<div class="field-tooltip">
  <ng-container *ngIf="fieldControl.disabled">
    <div [nzTooltipTitle]="'labelTexts.readOnly' | translate"
         data-cy="field-disabled-tooltip"
         nzTooltipPlacement="left"
         nz-tooltip
         [appIcon]="'info'" class="c-info-icon">
    </div>
  </ng-container>

  <ng-container *ngIf="fieldControl.required && !fieldControl.disabled">
    <div [nzTooltipTitle]="'labelTexts.required' | translate"
         data-cy="field-required-tooltip"
         nzTooltipPlacement="left"
         nz-tooltip
         [appIcon]="'info'"
         class="ml-2 c-edit-icon">
    </div>
  </ng-container>
</div>


