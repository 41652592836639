import { FormGroup } from '@angular/forms';

import {
  fieldsToJSON,
  IField,
  IFieldConfig, IFieldsData,
  IFormBuilder,
  Sections,
} from '@c-fields/common';

import { createForm } from './helpers/create-form';
import { FIELDS } from './fields-list';
import { createSections } from './helpers/create-sections';

/**
 * Form builder is needed for transformation Requested Fields configs into FormGroup
 * and toJSON before sending to the server
 *
 * In case when you want to customize this process – you can create your own implementation
 */
export class FormBuilder implements IFormBuilder {

  private _configs: IFieldConfig[];
  private _data: IFieldsData;
  private _form: FormGroup;
  private _sections: Sections = {};

  public get sections(): Sections {
    return this._sections;
  }

  /**
   * Configuration objects used for creation new Fields
   */
  public setConfigs(configs: IFieldConfig[]) {
    this._configs = configs;
  }

  /**
   * Set Data for Fields
   *
   * This data object will be passed as is into every Field
   * and Field will grab needed values in Field._setInitialValues method
   */
  public setData(data: IFieldsData) {
    this._data = data;
  }

  public build(): FormGroup {
    this._sections = createSections(
      this._configs,
      FIELDS,
      this._data,
    );

    this._form = createForm(this._sections);

    return this._form;
  }

  public toJSON(): IField[] {
    return fieldsToJSON(this._sections);
  }

}
