import { Component } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { ErrorFooterComponent } from '../../../libs/error-footer';


@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    ErrorFooterComponent,
  ],
})
export class ErrorComponent {}
