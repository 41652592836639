import { IFieldConfig, IFormField } from '@c-fields/common';

import { IFieldFactory } from '../../common/interfaces/field-factory.interface';

import { Birthday } from './birthday.field';


export class BirthdayFieldFactory implements IFieldFactory {

  public readonly isComplexField = false;

  public getFieldWithConfig(config: IFieldConfig): IFormField {
    return new Birthday(config);
  }

}
