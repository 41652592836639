export enum FeatureType {

  Person = 1,
  ReceiverMessage,
  ReceiverMessageCasual,
  SenderMessage,
  SenderMessageCasual,

  SharingMessage,
  SharingMessageCasual,
  SharingContactList,
  SharingVcard,
  SharingVcardLater,
  SharingReplyButton,
  Preferences,
  PreferenceMessage,
  PreferenceToggle,
  PreferenceOpen,
  PreferenceColored,
  NextTitleForSendButton,

  WorkFolder,
  WorkFolderOpen,
  HomeFolder,
  HomeFolderOpen,
  LegalHtml,
  Steps,

  Logo,
  Greeting,
  Terms,
  ContactsEditing,
  ContactsListing,
  Settings,

  TopBar,
  PersonInfo,
  ConfirmOnlyEmail,
  ConfirmFull,
  ConfirmMarketing,
  AppInfo,
  InlinePersonInfo,
  ShowAddressTitle,
  AddressInputTopIcon,
  ShowInfoInputsByDefault,
  CheckboxInlinePreferenceText,

  ShowDefaultConsents,
  ShowReoConsents,

  ShowAddressExtra1,
  ShowAddressExtra2,
  ShowAddressExtra3,

  ShowEmployee,
}

