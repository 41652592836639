import { CanActivateFn, ResolveFn } from '@angular/router';
import { inject } from '@angular/core';

import { VerificationType } from '@common/enums';

import { StoreService } from '../services/store.service';
import { AppNavService } from '../services/app-nav.service';

// GuardResolver means that it behaves like Guard, but implemented as Resolver
// by the reason of currently implemented DataFlow
// and the fact that in case it will be Guard – main resolver for Setup won't be called
export const bdayVerificationPageGuardResolver: ResolveFn<boolean> = () => {
  return bdayVerificationFn();
};

export const bdayVerificationFn = (
  storeService = inject(StoreService),
  appNavService = inject(AppNavService),
) => {
  const verificationType: VerificationType = storeService.setup?.verificationType;

  if (verificationType && verificationType === VerificationType.Birthday) {
    return true;
  }

  appNavService.goToErrorPage();

  return false;
};
