import { Component, OnDestroy } from '@angular/core';

import { PageType } from '@common/enums/page.type';
import { IconDirective } from '@common/directives';
import { TranslateModule } from '@ngx-translate/core';

import { PersistanceService } from '../../core/services/persistance.service';


@Component({
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
  standalone: true,
  imports: [
    IconDirective,
    TranslateModule,
  ],
})
export class ConfirmationComponent implements OnDestroy {

  public readonly pageType: PageType = PageType.Confirmation;

  constructor(
    private _persistanceService: PersistanceService,
  ) {}

  public ngOnDestroy(): void {
    this._persistanceService.setItem('latestPageType', String(this.pageType));
  }

}
