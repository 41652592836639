import { IField } from '../interfaces/field.interface';
import { Sections } from '../types/sections.type';
import { IFormField } from '../interfaces/form-field.interface';


/**
 * Does transformation from Web App structures into Service (backend) Field format
 */
export function fieldsToJSON(sections: Sections): IField[] {
  const fieldsArrays = Object.values(sections);

  return fieldsArrays
    .reduce((acc: IField[], fields) => {
      const results = processFormSection(fields);

      acc.push(...results);

      return acc;
    }, []);
}

export function processFormSection(fields: IFormField[]): IField[] {
  return fields
    .reduce((acc: IField[], field) => {
      acc.push( {
        section: field.section,
        type: field.type,
        inputs: field.toJSON(),
      });

      return acc;
    }, []);

}
