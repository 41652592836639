import { IFieldConfig, IFormField } from '@c-fields/common';

import { IFieldFactory } from '../../common/interfaces/field-factory.interface';

import { EmailField } from './email.field';


export class EmailFieldFactory implements IFieldFactory {

  public readonly isComplexField = false;

  public getFieldWithConfig(config: IFieldConfig): IFormField {
    return new EmailField(config);
  }

}
