import { Section } from '../enums/section.enum';
import { IFieldRemoteConfig } from '../interfaces/field-remote-config.interface';
import { Field } from '../enums/field.enum';
import { IFieldConfig } from '../interfaces/field-config.interface';


export function remoteToFieldConfig(config: IFieldRemoteConfig): IFieldConfig {
  return {
    section: config.section as Section,
    type: config.type as Field,
    required: config.required ?? false,
    readonly: config.readonly ?? false,
    limit: config.limit ?? 1,
  };
}
