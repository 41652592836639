<div class="c-category">
  <div class="c-category-header custom-light-panel"
       [ngClass]="{ 'collapsable': collapsable }"
       (click)="toggleCollapse()">
    <div [appIcon]="icon" [size]="24" class="c-category-icon custom-category-icons" data-cy="category-icon"></div>
    <div class="c-category-title custom-category-title" data-cy="category-title">
      {{ title }}
    </div>

    <ng-container *ngIf="collapsable">
      <div *ngIf="collapsed"
           class="c-category-arrow custom-category-arrows"
           data-cy="category-collapse-icon"
           [appIcon]="'arrow_down'">
      </div>

      <div *ngIf="!collapsed"
           class="c-category-arrow custom-category-arrows"
           data-cy="category-collapse-icon"
           [appIcon]="'arrow_up'">
      </div>
    </ng-container>
  </div>
  <div class="c-category-content" [@openClose]="!collapsed ? 'open' : 'closed'" data-cy="category-content">
    <div class="c-container custom-category-container">
      <ng-content></ng-content>
    </div>
  </div>
</div>
