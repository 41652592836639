import { InfoItem } from './Info-item';


export class Address extends InfoItem {

  city: string;
  country: string;
  postcode: any;
  street: string;

  constructor(data: Address) {
    super(data);
    Object.assign(this, data);
  }

  isEmpty?() {
    return [
      this.country,
      this.city,
      String(this.postcode),
      this.street,
    ].every((x) => !x || x.trim() === '');
  }

}


