import { isDate } from './is-date';


export function isDateValid(value: Date): boolean {
  if (!isDate(value)) {
    return false;
  }

  const date = new Date(value.getTime());

  return !isNaN(Number(date));
}
