<ng-container [formGroup]="form">
  <ng-container [formArrayName]="field.type">
    <ng-container [ngSwitch]="field.type">
      <ng-template [ngSwitchCase]="'email'">
        <form-field-wrapper icon="email" data-cy="email-field">
          <form-field>
            <field-label data-cy="email-field-label">{{'labelTexts.email' | translate}}</field-label>

            <input type="email"
                   fieldInput
                   [formControlName]="index"
                   class="form-control c-input"
                   [placeholder]="'receiver.placeholders.email' | translate"
                   data-cy="email-field-input">
          </form-field>
        </form-field-wrapper>
      </ng-template>

      <ng-template [ngSwitchCase]="'full_address'">
        <form-field-wrapper icon="place" data-cy="full-address-field">
          <app-address-form-item
            [formGroupName]="index"
            [showAddressExtra3]="showAddressExtra3">
          </app-address-form-item>
        </form-field-wrapper>
      </ng-template>

      <ng-template [ngSwitchCase]="'birthday'">
        <form-field-wrapper icon="event" data-cy="birthday-field">
          <form-field>
            <field-label data-cy="birthday-field-label">{{'labelTexts.birthday' | translate}}</field-label>

            <app-birthday-form-item
              fieldInput
              [formControlName]="index">
            </app-birthday-form-item>
          </form-field>
        </form-field-wrapper>
      </ng-template>

      <ng-template [ngSwitchCase]="'phone'">
        <form-field-wrapper icon="phone" data-cy="phone-field">
          <form-field>
            <field-label data-cy="phone-field-label">{{'labelTexts.phone' | translate}}</field-label>

            <input type="tel"
                   fieldInput
                   [formControlName]="index"
                   class="form-control c-input"
                   [placeholder]="'receiver.placeholders.phone' | translate"
                   data-cy="phone-field-input">
          </form-field>
        </form-field-wrapper>
      </ng-template>


      <ng-template [ngSwitchCase]="'mobile'">
        <form-field-wrapper icon="mobile" data-cy="mobile-field">
          <form-field>
            <field-label data-cy="mobile-field-label">{{'labelTexts.mobile' | translate}}</field-label>

            <input type="tel"
                   fieldInput
                   [formControlName]="index"
                   class="form-control c-input"
                   [placeholder]="'receiver.placeholders.mobile' | translate"
                   data-cy="mobile-field">
          </form-field>
        </form-field-wrapper>
      </ng-template>
    </ng-container>
  </ng-container>
</ng-container>
