import { IFieldConfig, IFormField, IFieldFactory, IFieldsData } from '@c-fields/common';


// uses fieldFactory to create new IFormField with data
export function createFieldFromConfig(
  config: IFieldConfig,
  fieldFactory: IFieldFactory,
  data: IFieldsData,
): IFormField {
  let field = fieldFactory.getFieldWithConfig(config);
  if (!field) {
    // TODO throw soft error to notify glitchtip
    return;
  }

  field.initWithValues(data);

  return field;
}
