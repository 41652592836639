<div class="c-person-inline custom-person-inline">
  <div class="c-img-container">
    <div [appIcon]="'person'" [size]="16" class="c-person-icon" data-cy="person-icon"></div>
  </div>
  <span *ngIf="firstName" data-cy="first-name-label">
    {{ firstName }}&nbsp;
  </span>
  <span *ngIf="lastName" data-cy="last-name-label">
    {{ lastName }}
  </span>
</div>
