import { ModuleWithProviders, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { IconDefinition } from '@ant-design/icons-angular';
import { LoadingOutline } from '@ant-design/icons-angular/icons';

import { AppNavService } from './services/app-nav.service';
import { StoreService } from './services/store.service';
import { ApiService } from './services/api.service';
import { SetupResolver } from './resolvers/setup.resolver';
import { TelService } from './services/tel.service';
import { TranslationModule } from './modules/translation';


const icons: IconDefinition[] = [LoadingOutline];

@NgModule({ imports: [BrowserModule,
  BrowserAnimationsModule,
  TranslationModule.forRoot(),
  NzIconModule.forRoot(icons)], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        AppNavService,
        StoreService,
        ApiService,
        SetupResolver,
        TelService,
      ],
    };
  }
}
