import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  inject,
  Input,
} from '@angular/core';

import { FormFieldComponent } from '../form-field';
import { FieldControl } from '../../directives/field-control.directive';


@Component({
  // eslint-disable-next-line
  selector: 'form-field-wrapper',
  templateUrl: './form-field-wrapper.component.html',
  styleUrls: [
    './form-field-wrapper.component.scss',
  ],
  host: {
    'class': 'c-form-control-container',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormFieldWrapperComponent implements AfterViewInit {

  @Input()
  public icon: string;

  @ContentChild(FormFieldComponent, { static: true })
  public readonly formFieldControl: FormFieldComponent;

  @ContentChild(FieldControl, { static: true })
  public readonly _formFieldControl: FieldControl;

  private _cdRef = inject(ChangeDetectorRef);

  public get fieldControl(): FieldControl {
    return this.formFieldControl?.fieldControl || this._formFieldControl;
  }

  public ngAfterViewInit(): void {
    // run change detection again
    // because during this cycle FieldControl might change its state
    this._cdRef.markForCheck();

    // and listen new changes
    this._listenStatusChanges();
  }

  /**
   * FormField should properly react on Control changes
   * ex.: validators updated or disabled state changed
   */
  private _listenStatusChanges(): void {
    this.fieldControl
      .statusChanges
      .subscribe(() => {
        this._cdRef.detectChanges();
      });
  }
}
