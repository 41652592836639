import { Component, OnDestroy, OnInit } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { NgIf } from '@angular/common';

import { TranslateService } from '@ngx-translate/core';

import { Observable, Subject } from 'rxjs';
import { delay, map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { LoaderService } from '../../loader.service';
import { TimeoutType } from '../../enums';
import { environment } from '../../../../environments/environment';
import { StoreService } from '../../../../app/core/services/store.service';
import { LoaderType } from '../../../../libs/common/enums/loader.type';


@Component({
  templateUrl: './loader-screen.component.html',
  styleUrls: ['./loader-screen.component.scss'],
  standalone: true,
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(':enter', [   // :enter is alias to 'void => *'
          style({ opacity: 0 }),
          animate(300, style({ opacity: 1 })),
        ]),
      ],
    ),
  ],
  imports: [
    NgIf,
  ],
})
export class LoaderScreenComponent implements OnInit, OnDestroy {

  public message = '';

  private _destroy$ = new Subject<void>();

  constructor(
    public storeService: StoreService,
    private _loaderService: LoaderService,
    private _translateService: TranslateService,
  ) {}

  public get defaultLoader(): boolean {
    const type = this.storeService?.setup?.loaderType;

    return (!type) || type === LoaderType.Default;
  }

  public get circleLoader(): boolean {
    return this.storeService?.setup?.loaderType === LoaderType.Circle;
  }

  public ngOnInit(): void {
    this._listenLoadingTimeout();
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  private _listenLoadingTimeout(): void {
    this._loaderService
      .timeout$
      .pipe(
        switchMap((type: TimeoutType) => {
          return this._getShortTimeoutText(type)
            .pipe(
              tap((text: string) => {
                this.message = text;
              }),
              map(() => type),
            );
        }),
        delay(environment.longRequestTimeout),
        switchMap((type: TimeoutType) => {
          return this._getLongTimeoutText(type);
        }),
        tap((text: string) => {
          this.message = text;
        }),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  private _getShortTimeoutText(type: TimeoutType): Observable<string> {
    switch (type) {
      case TimeoutType.Submit: {
        return this._translateService.get('global.shortSubmitTime');
      }

      case TimeoutType.Receive: {
        return this._translateService.get('global.shortReceiveTime');
      }
    }
  }

  private _getLongTimeoutText(type: TimeoutType): Observable<string> {
    switch (type) {
      case TimeoutType.Submit: {
        return this._translateService.get('global.longSubmitTime');
      }

      case TimeoutType.Receive: {
        return this._translateService.get('global.longReceiveTime');
      }
    }
  }

}
