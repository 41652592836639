import { Directive } from '@angular/core';


@Directive({
  // eslint-disable-next-line
  selector: 'field-label',
})
export class FieldLabelDirective {

}
