import {
  createFieldInput,
  FieldInputType,
  IFieldInput,
  IFieldsData,
  IFormField,
  SimpleField,
} from '@c-fields/common';
import { isoStringToZonelessDate, isDateValid } from '@common/helpers/date';
import { birthdayToISOString } from '@common/helpers/date/birthday-to-iso-string';

import { FormControlBuilder } from '../../common/builders/form-control.builder';


export class Birthday extends SimpleField<Date> implements IFormField<Date> {

  protected get _canAddEmptyFormControl(): boolean {
    return this.formArray.controls.length < 1;
  }

  public toJSON(): IFieldInput[] {
    return this._formArray.value
      .map((value: Date, index: number) => {
        if (isDateValid(value)) {
          return createFieldInput<string>(
            FieldInputType.Birthday,
            index,
            birthdayToISOString(value),
          );
        }

        return createFieldInput<string>(FieldInputType.Birthday, index, null);

      });
  }

  protected _buildFormControl(index: number): FormControlBuilder {

    const builder = super._buildFormControl(index);
    builder.addBirthdayValidator();

    return builder;
  }


  protected _setInitialValues(values: IFieldsData): void {
    const bdayStr: string = values?.person?.birthday;

    if (bdayStr) {
      const parsed: Date = isoStringToZonelessDate(bdayStr);

      // TODO assertions later when dateIsNotValid
      if (isDateValid(parsed) && parsed.getFullYear() > 1900) {
        this._initialValues = [parsed];
      }
    }
  }

}
