<p class="help-text">{{"expired.buttonHelpText" | translate}}</p>
<div class="c-button-container">
        <span>
          Jetzt mitmachen:
        </span>
  <button class="btn btn-primary custom-btn-primary"
          (click)="onSetAppointmentClick()">
    {{"expired.buttonTitle" | translate}}
  </button>
</div>

<div class="c-text-container">
  <p>
    <span>Complero © {{ currentYear }} Complero GmbH</span><br>
    <span>Im Wiesengrund 49</span><br>
    <span>53539 Kelberg</span><br>
    <br>
    <span><a href="tel:02692-2689886">02692-2689886</a></span><br>
    <span><a href="mailto:service@complero.com">service&#64;complero.com</a></span>
  </p>
</div>
