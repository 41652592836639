import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
import { IconDirective } from '@common/directives';
import { SafeHtml } from '@common/pipes/safe.pipe';

import { NgxVcardModule } from 'ngx-vcard';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ImageCropperComponent } from 'ngx-image-cropper';
import { NgxPrettyCheckboxModule } from 'ngx-pretty-checkbox';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { MarkdownModule } from 'ngx-markdown';


import { CollapsableSectionComponent } from '../../libs/collapsable-section';
import { ConsentsListComponent } from '../../libs/consents-list/consents-list.component';

import { MessagesComponent } from './messages/messages.component';
import { AppLoaderComponent } from './app-loader/app-loader.component';
import { MailtoComponent } from './mailto/mailto.component';
import { InfoComponent } from './info/info.component';
import { SenderMessageElementComponent } from './sender-message-element/sender-message-element.component';
import { AvatarEditComponent } from './avatar-edit/avatar-edit.component';
import { ReceiverMessageElementComponent } from './receiver-message-element/receiver-message-element.component';
import { AppTopBarComponent } from './topbar/app-topbar.component';
import { PopoverComponent } from './popover/popover.component';


const components = [
  MessagesComponent,
  AppLoaderComponent,
  MailtoComponent,
  InfoComponent,
  AvatarEditComponent,
  SenderMessageElementComponent,
  ReceiverMessageElementComponent,
  AppTopBarComponent,
];

const modules = [
  CommonModule,
  RouterModule,
  TranslateModule,
  FormsModule,
  NgxVcardModule,
  NgbModule,
  NgxPrettyCheckboxModule,
  NzSelectModule,
  NzIconModule,
  NzAutocompleteModule,
  NzToolTipModule,
  MarkdownModule.forRoot(),

  ImageCropperComponent,
];

@NgModule({
  declarations: [
    components,
    PopoverComponent,
  ],
  imports: [
    modules,
    IconDirective,
    CollapsableSectionComponent,
    ConsentsListComponent,
    SafeHtml,
    ImageCropperComponent,
  ],
  exports: [
    modules,
    components,
    PopoverComponent,
    IconDirective,
  ],
})
export class SharedModule { }
