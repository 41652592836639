/* eslint-disable */
import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';


@Injectable()
export class PopoverService {
  private _content$ = new BehaviorSubject<string>(null);
  public content$ = this._content$.asObservable();
  target: EventTarget;
  tag: string;
  dataAttribute: string;
  top: number;
  beginning: number;
  ending: number;

  private _showPopoverHandler = this._showPopover.bind(this);
  private _hidePopoverHandler = this._hidePopoverDebounced.bind(this);
  private _elements: NodeListOf<Element>;
  private _hideTimeoutID: ReturnType<typeof setTimeout>;

  watchTargets(tag: string, dataAttribute: string) {
    this.destroyWatchers();

    this.tag = tag;
    this.dataAttribute = dataAttribute;
    this._hidePopover();

    setTimeout(() => {
      this._elements = document.querySelectorAll(`${this.tag  }[${this.dataAttribute}]`);
      this._elements.forEach((item) => {
        item.addEventListener('click', this._showPopoverHandler);
        item.addEventListener('mouseover', this._showPopoverHandler);
        item.addEventListener('mouseleave', this._hidePopoverHandler);
      });

      /* ----------------------------------------
       * Watch body events changes
       * -------------------------------------- */
      document.body.addEventListener('click', () => {
        this._hidePopover();
      });
      document.body.addEventListener('scroll', () => {
        this.updateTop();
      });

    }, 200);


  }

  updateTop() {
    if (this.target) {
      this.top = document.documentElement.scrollTop + (this.target as HTMLLinkElement).getBoundingClientRect().top;
    }
  }

  setContent(content: string | null) {
    this._content$.next(content);
  }


  removePopover() {
    this.setContent(null);
    this.target = null;
  }

  public destroyWatchers(): void {
    if (!this._elements) {
      return
    }

    this._elements.forEach((item) => {
      item.removeEventListener('click', this._showPopoverHandler);
      item.removeEventListener('mouseover', this._showPopoverHandler);
      item.removeEventListener('mouseleave', this._hidePopoverDebounced);
    });

    this._elements = null;
  }

  private removeAllLinkBeginningSpans() {
    const els = document.querySelectorAll('span.data-link-beginning');
    els.forEach((item) => item.parentNode.removeChild(item));
  }

  private _showPopover(event): void {
    clearTimeout(this._hideTimeoutID);

    event.preventDefault();
    event.stopPropagation();

    this.removeAllLinkBeginningSpans();
    /* ----------------------------------------
     * Set target and top position
     * -------------------------------------- */
    const el = (event.target as HTMLLinkElement);
    this.target = el;
    this.updateTop();
    /* ----------------------------------------
     * Add link beginning and ending for getting starting point
     * -------------------------------------- */
    const linkStartSpan = document.createElement('span');
    linkStartSpan.setAttribute('class', 'data-link-beginning');
    el.prepend(linkStartSpan);
    this.beginning = Math.round(linkStartSpan.getBoundingClientRect().left);
    this.ending = Math.round(el.offsetWidth + el.getBoundingClientRect().left);
    /* ----------------------------------------
     * Get content for current popover
     * -------------------------------------- */
    const templateId = el.getAttribute(this.dataAttribute);
    const template = document.getElementById(templateId);
    if (template) {
      this.setContent(template.innerHTML);
    }
  }

  private _hidePopover(): void {
    this.setContent(null);
  }

  private _hidePopoverDebounced(): void {
    this._hideTimeoutID = setTimeout(() => {
      this._hidePopover();
    }, 500);
  }
}
