export const iconList = [
  'place',
  'org',
  'link',
  'phone',
  'mobile',
  'fax',
  'event',
  'mail',
  'work',
  'personal',
  'preferences',
  'shared',
  'edit',
  'more',
  'delete',
  'clear',
  'avatar_placeholder',
  'photo_placeholder',
  'add_photo',
  'arrow_right',
  'arrow_left',
  'arrow_down',
  'arrow_up',
  'csv',
  'info',
  'person',
  'tel_screen',
  'tel_doc',
  'success',
  'calendar',
];

export const iconsLibrary = {

  /* eslint-disable */
  // Info items and Inputs
  // NO "PX" in height and weight
  'place': '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>',
  'org': '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0zm10 5h4v2h-4zm0 0h4v2h-4z"/><path d="M10 16v-1H3.01L3 19c0 1.11.89 2 2 2h14c1.11 0 2-.89 2-2v-4h-7v1h-4zm10-9h-4.01V5l-2-2h-4l-2 2v2H4c-1.1 0-2 .9-2 2v3c0 1.11.89 2 2 2h6v-2h4v2h6c1.1 0 2-.9 2-2V9c0-1.1-.9-2-2-2zm-6 0h-4V5h4v2z"/></svg>',
  'link': '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M3.9 12c0-1.71 1.39-3.1 3.1-3.1h4V7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-1.9H7c-1.71 0-3.1-1.39-3.1-3.1zM8 13h8v-2H8v2zm9-6h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1s-1.39 3.1-3.1 3.1h-4V17h4c2.76 0 5-2.24 5-5s-2.24-5-5-5z"/></svg>',
  'phone': '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V20c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2z"/></svg>',
  'mobile': '<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M16 1H8C6.34 1 5 2.34 5 4v16c0 1.66 1.34 3 3 3h8c1.66 0 3-1.34 3-3V4c0-1.66-1.34-3-3-3zm-2 20h-4v-1h4v1zm3.25-3H6.75V4h10.5v14z"/></svg>',
  'fax': '<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 8H5c-1.66 0-3 1.34-3 3v6h4v4h12v-4h4v-6c0-1.66-1.34-3-3-3zm-3 11H8v-5h8v5zm3-7c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm-1-9H6v4h12V3z"/></svg>',
  'event': '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M17 12h-5v5h5v-5zM16 1v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2h-1V1h-2zm3 18H5V8h14v11z"/><path d="M0 0h24v24H0z" fill="none"/></svg>',
  'email': '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"  viewBox="0 0 512 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M0 128C0 92.65 28.65 64 64 64H448C483.3 64 512 92.65 512 128V384C512 419.3 483.3 448 448 448H64C28.65 448 0 419.3 0 384V128zM48 128V150.1L220.5 291.7C241.1 308.7 270.9 308.7 291.5 291.7L464 150.1V127.1C464 119.2 456.8 111.1 448 111.1H64C55.16 111.1 48 119.2 48 127.1L48 128zM48 212.2V384C48 392.8 55.16 400 64 400H448C456.8 400 464 392.8 464 384V212.2L322 328.8C283.6 360.3 228.4 360.3 189.1 328.8L48 212.2z"/></svg>',

  // Category headers
  'work': '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill-rule="evenodd" clip-rule="evenodd" d="M14 6V4h-4v2h4zM4 8v11h16V8H4zm16-2c1.11 0 2 .89 2 2v11c0 1.11-.89 2-2 2H4c-1.11 0-2-.89-2-2l.01-11c0-1.11.88-2 1.99-2h4V4c0-1.11.89-2 2-2h4c1.11 0 2 .89 2 2v2h4z"/><path fill="none" d="M0 0h24v24H0z"/></svg>',
  'personal': '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 5.9c1.16 0 2.1.94 2.1 2.1s-.94 2.1-2.1 2.1S9.9 9.16 9.9 8s.94-2.1 2.1-2.1m0 9c2.97 0 6.1 1.46 6.1 2.1v1.1H5.9V17c0-.64 3.13-2.1 6.1-2.1M12 4C9.79 4 8 5.79 8 8s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 9c-2.67 0-8 1.34-8 4v3h16v-3c0-2.66-5.33-4-8-4z"/><path d="M0 0h24v24H0z" fill="none"/></svg>',
  'preferences': '<svg enable-background="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m0 0h24v24h-24z" fill="none"/><path d="m16.54 11-3.54-3.54 1.41-1.41 2.12 2.12 4.24-4.24 1.41 1.41zm-5.54-4h-9v2h9zm10 6.41-1.41-1.41-2.59 2.59-2.59-2.59-1.41 1.41 2.59 2.59-2.59 2.59 1.41 1.41 2.59-2.59 2.59 2.59 1.41-1.41-2.59-2.59zm-10 1.59h-9v2h9z"/></svg>',
  'shared': '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zm6.93 6h-2.95c-.32-1.25-.78-2.45-1.38-3.56 1.84.63 3.37 1.91 4.33 3.56zM12 4.04c.83 1.2 1.48 2.53 1.91 3.96h-3.82c.43-1.43 1.08-2.76 1.91-3.96zM4.26 14C4.1 13.36 4 12.69 4 12s.1-1.36.26-2h3.38c-.08.66-.14 1.32-.14 2s.06 1.34.14 2H4.26zm.82 2h2.95c.32 1.25.78 2.45 1.38 3.56-1.84-.63-3.37-1.9-4.33-3.56zm2.95-8H5.08c.96-1.66 2.49-2.93 4.33-3.56C8.81 5.55 8.35 6.75 8.03 8zM12 19.96c-.83-1.2-1.48-2.53-1.91-3.96h3.82c-.43 1.43-1.08 2.76-1.91 3.96zM14.34 14H9.66c-.09-.66-.16-1.32-.16-2s.07-1.35.16-2h4.68c.09.65.16 1.32.16 2s-.07 1.34-.16 2zm.25 5.56c.6-1.11 1.06-2.31 1.38-3.56h2.95c-.96 1.65-2.49 2.93-4.33 3.56zM16.36 14c.08-.66.14-1.32.14-2s-.06-1.34-.14-2h3.38c.16.64.26 1.31.26 2s-.1 1.36-.26 2h-3.38z"/></svg>',
  'calendar': '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M7 1a1 1 0 0 0-1 1v1H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h11.172l-2-2H5V8h14v6.172l2 2V5a2 2 0 0 0-2-2h-1V2a1 1 0 0 0-2 0v1H8V2a1 1 0 0 0-1-1zm1 10a1 1 0 0 0 0 2h8a1 1 0 0 0 0-2H8zm0 4a1 1 0 0 0 0 2h4a1 1 0 0 0 0-2H8zm7.5 0a.5.5 0 0 0-.5.5v1.293a.5.5 0 0 0 .146.353l5 5 2-2-5-5a.503.503 0 0 0-.353-.146H15.5zm7.354 5.854-2 2 .732.732a1.415 1.415 0 0 0 2-2l-.732-.732z"/></svg>',

  // Item Menu
  'edit': '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/><path d="M0 0h24v24H0z" fill="none"/></svg>',
  'more': '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M6 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"/></svg>',
  'delete': '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zm2.46-7.12l1.41-1.41L12 12.59l2.12-2.12 1.41 1.41L13.41 14l2.12 2.12-1.41 1.41L12 15.41l-2.12 2.12-1.41-1.41L10.59 14l-2.13-2.12zM15.5 4l-1-1h-5l-1 1H5v2h14V4z"/><path fill="none" d="M0 0h24v24H0z"/></svg>',
  'clear': '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg>',

  'avatar_placeholder': '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"/><path d="M0 0h24v24H0z" fill="none"/></svg>',
  'photo_placeholder': '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 12.25c1.24 0 2.25-1.01 2.25-2.25S13.24 7.75 12 7.75 9.75 8.76 9.75 10s1.01 2.25 2.25 2.25zm4.5 4c0-1.5-3-2.25-4.5-2.25s-4.5.75-4.5 2.25V17h9v-.75zM19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14z"/></svg>',
  'add_photo': '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19 7v2.99s-1.99.01-2 0V7h-3s.01-1.99 0-2h3V2h2v3h3v2h-3zm-3 4V8h-3V5H5c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-8h-3zM5 19l3-4 2 3 3-4 4 5H5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>',

  'arrow_right': '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"/></svg>',
  'arrow_left': '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"/></svg>',
  'arrow_down': '<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"/></svg>',
  'arrow_up': '<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6 1.41 1.41z"/></svg>',

  'csv': '<svg width="24" height="24" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="file-spreadsheet" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-file-spreadsheet fa-w-12 fa-3x"><path fill="currentColor" d="M369.9 97.98L286.02 14.1c-9-9-21.2-14.1-33.89-14.1H47.99C21.5.1 0 21.6 0 48.09v415.92C0 490.5 21.5 512 47.99 512h288.02c26.49 0 47.99-21.5 47.99-47.99V131.97c0-12.69-5.1-24.99-14.1-33.99zM256.03 32.59c2.8.7 5.3 2.1 7.4 4.2l83.88 83.88c2.1 2.1 3.5 4.6 4.2 7.4h-95.48V32.59zm95.98 431.42c0 8.8-7.2 16-16 16H47.99c-8.8 0-16-7.2-16-16V48.09c0-8.8 7.2-16.09 16-16.09h176.04v104.07c0 13.3 10.7 23.93 24 23.93h103.98v304.01zM64 208v224c0 8.84 7.16 16 16 16h224c8.84 0 16-7.16 16-16V208c0-8.84-7.16-16-16-16H80c-8.84 0-16 7.16-16 16zm224 208h-80v-48h80v48zm0-80h-80v-48h80v48zM96 224h192v32H96v-32zm0 64h80v48H96v-48zm0 80h80v48H96v-48z" class=""></path></svg>',
  'info': '<svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 24 24" ><path d="M0 0h24v24H0V0z" fill="none"/><path d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"/></svg>',
  'person': '<svg width="16" height="17"><path d="M3.556 4.444A4.45 4.45 0 0 0 8 8.89a4.45 4.45 0 0 0 4.444-4.445A4.45 4.45 0 0 0 8 0a4.45 4.45 0 0 0-4.444 4.444Zm1.777 0A2.67 2.67 0 0 1 8 1.778a2.67 2.67 0 0 1 2.667 2.666A2.67 2.67 0 0 1 8 7.111a2.67 2.67 0 0 1-2.667-2.667ZM16 16a6.23 6.23 0 0 0-6.222-6.222H6.222A6.23 6.23 0 0 0 0 16v.889h1.778V16a4.45 4.45 0 0 1 4.444-4.444h3.556A4.45 4.45 0 0 1 14.222 16v.889H16V16Z" fill-rule="nonzero"/></svg>',
  'tel_screen': '<svg width="24px" height="16px" viewBox="0 0 24 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">\n' +
    '    <defs>\n' +
    '        <path d="M24,13.3995037 C24,14.4913151 23.1000001,15.3846154 22,15.3846154 L22,15.3846154 L2,15.3846154 C0.900000004,15.3846154 2.15895677e-14,14.4913151 2.15895677e-14,13.3995037 L2.15895677e-14,13.3995037 Z M18.5,-7.44703444e-14 C20.1500001,-7.44703444e-14 21.5,1.33995037 21.5,2.9776675 L21.5,2.9776675 L21.5,11.91067 L20,11.91067 L20,2.9776675 C20,2.13399504 19.3500001,1.48883375 18.5,1.48883375 L18.5,1.48883375 L5.50000001,1.48883375 C4.65000002,1.48883375 4.00000001,2.13399504 4.00000001,2.9776675 L4.00000001,2.9776675 L4.00000001,11.91067 L2.50000001,11.91067 L2.50000001,2.9776675 C2.50000001,1.33995037 3.85000002,-7.44703444e-14 5.50000001,-7.44703444e-14 L5.50000001,-7.44703444e-14 Z M7.50000003,4.21836229 L11.15,7.74193549 L9.50000003,7.59305211 L10.5,9.97518611 L9.55000004,10.3722084 L8.55000004,7.99007445 L7.50000003,9.28039703 L7.50000003,4.21836229 Z" id="path-2"></path>\n' +
    '    </defs>\n' +
    '    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">\n' +
    '        <g id="Laptop">\n' +
    '            <g>\n' +
    '                <mask id="mask-3" fill="white">\n' +
    '                    <use xlink:href="#path-2"></use>\n' +
    '                </mask>\n' +
    '                <use id="Combined-Shape" fill="#E20074" xlink:href="#path-2"></use>\n' +
    '            </g>\n' +
    '        </g>\n' +
    '    </g>\n' +
    '</svg>',
  'tel_doc': '<svg style="margin-left:-3px" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">\n' +
    '    <defs>\n' +
    '        <rect id="path-1" x="0" y="0" width="24" height="24"></rect>\n' +
    '    </defs>\n' +
    '    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">\n' +
    '        <g id="Document">\n' +
    '            <mask id="mask-2" fill="white">\n' +
    '                <use xlink:href="#path-1"></use>\n' +
    '            </mask>\n' +
    '            <g id="path-1"></g>\n' +
    '            <path d="M19.9039993,8.58600044 C19.8549993,8.48000044 19.7940002,8.37900022 19.7080002,8.29300022 L13.7080002,2.29299998 C13.6220002,2.20699998 13.521,2.14599988 13.415,2.09699988 C13.385,2.08299988 13.3530001,2.07499989 13.3210001,2.06399989 C13.2370001,2.03599989 13.1510003,2.01800001 13.0620003,2.01300001 C13.0400003,2.01100001 13.021,2 13,2 L6,2 C4.89699996,2 4,2.89699996 4,4 L4,20 C4,21.103 4.89699996,22 6,22 L18,22 C19.103,22 20,21.103 20,20 L20,9 C20,8.979 19.9889995,8.95999973 19.9869995,8.93799973 C19.9819995,8.84999973 19.9650003,8.7640003 19.9370003,8.68000031 C19.9260003,8.6480003 19.9169993,8.61700044 19.9039993,8.58600044 Z M14,8 L14,5.41400003 L16.5860004,8 L14,8 Z M6,4 L12,4 L12,9 C12,9.55299997 12.447,10 13,10 L18,10 L18.0020008,20 L6,20 L6,4 Z" id="Shape" fill="#E20074" fill-rule="nonzero" mask="url(#mask-2)"></path>\n' +
    '        </g>\n' +
    '    </g>\n' +
    '</svg>',
  'success': '<svg width="34" height="34" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M22.4 5.05c-.3-.3-.75-.3-1.05 0L9 17.4l-5.9-5.9c-.3-.3-.75-.3-1.05 0-.3.3-.3.75 0 1.05L9 19.5 22.4 6.1c.3-.3.3-.75 0-1.05Z" fill-rule="evenodd"/></svg>',
  'email_confirmation': `
    <svg width="105" height="151" viewBox="0 0 105 151" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M63.5916 44.8516C53.9789 44.8515 48.8029 41.114 39.9296 40.3665C34.5071 39.619 23.9578 39.7685 25.1409 46.3467C26.6198 54.5694 43.6268 62.0446 47.324 70.2675C49.1851 74.4068 50.2817 76.995 51.7606 76.995C53.2394 76.995 54.5112 75.9192 56.1972 72.51C58.4155 68.0247 63.8381 62.2939 67.2888 59.0546C73.2043 53.0744 86.514 40.3665 80.5986 40.3665C76.162 40.3665 77.6604 44.8518 63.5916 44.8516Z" fill="white"/>
      <path d="M8.87324 13.4554C8.87324 14.2447 8.87129 15.0314 8.86935 15.8156C8.83113 31.2478 8.79533 45.7049 23.6565 59.2263C25.4773 60.8829 27.1004 62.3521 28.5478 63.6621C33.0329 67.7217 35.8304 70.2539 37.5919 72.1032C38.7439 73.3126 39.3019 74.0795 39.5911 74.6564C39.8406 75.1544 39.9292 75.5859 39.9292 76.2475H39.935C39.935 76.9091 39.8464 77.3407 39.5968 77.8386C39.3077 78.4156 38.7497 79.1825 37.5977 80.3919C35.8362 82.2411 33.0389 84.773 28.5538 88.8327C27.1065 90.1427 25.4831 91.6121 23.6623 93.2688C8.80111 106.79 8.83691 121.247 8.87513 136.679C8.87707 137.464 8.87902 138.25 8.87902 139.04H13.3156C13.3156 138.331 13.3145 137.629 13.3133 136.933C13.2871 121.345 13.2659 108.762 26.6303 96.6025C28.3785 95.0119 29.9579 93.5825 31.3831 92.2926C35.93 88.1775 38.9081 85.4822 40.7935 83.5029C42.0447 82.1894 42.9655 81.0408 43.5545 79.8657C44.183 78.6117 44.3716 77.4547 44.3716 76.2475L44.3659 76.2475C44.3659 75.0403 44.1772 73.8834 43.5487 72.6293C42.9598 71.4543 42.0389 70.3056 40.7877 68.9921C38.9023 67.0128 35.9243 64.3175 31.3774 60.2024C29.9522 58.9126 28.3725 57.483 26.6245 55.8925C13.2601 43.733 13.2813 31.1503 13.3075 15.5618C13.3087 14.8658 13.3099 14.1636 13.3099 13.4554H8.87324Z" fill="white"/>
      <path d="M96.132 13.4554C96.132 14.2447 96.134 15.0314 96.1359 15.8156C96.1741 31.2478 96.2099 45.7049 81.3487 59.2263C79.5279 60.8829 77.9048 62.3521 76.4575 63.6621C71.9724 67.7217 69.1749 70.2539 67.4133 72.1032C66.2614 73.3126 65.7034 74.0795 65.4142 74.6564C65.1646 75.1544 65.076 75.5859 65.076 76.2475H65.0702C65.0702 76.9091 65.1589 77.3407 65.4084 77.8386C65.6976 78.4156 66.2556 79.1825 67.4076 80.3919C69.1691 82.2411 71.9663 84.773 76.4514 88.8327C77.8988 90.1427 79.5222 91.6121 81.3429 93.2688C96.2041 106.79 96.1684 121.247 96.1301 136.679C96.1282 137.464 96.1262 138.25 96.1262 139.04H91.6896C91.6896 138.331 91.6908 137.629 91.692 136.933C91.7182 121.345 91.7394 108.762 78.375 96.6025C76.6268 95.0119 75.0474 93.5825 73.6221 92.2926C69.0752 88.1775 66.0972 85.4822 64.2118 83.5029C62.9606 82.1894 62.0397 81.0408 61.4508 79.8657C60.8223 78.6117 60.6336 77.4547 60.6336 76.2475L60.6394 76.2475C60.6394 75.0403 60.8281 73.8834 61.4566 72.6293C62.0455 71.4543 62.9664 70.3056 64.2176 68.9921C66.1029 67.0128 69.081 64.3175 73.6279 60.2024C75.0531 58.9126 76.6327 57.483 78.3808 55.8925C91.7451 43.733 91.724 31.1503 91.6977 15.5618C91.6966 14.8658 91.6954 14.1636 91.6954 13.4554H96.132Z" fill="white"/>
      <path d="M0 1.49505C0 0.669356 0.662114 0 1.47887 0H103.521C104.338 0 105 0.669356 105 1.49505V11.9604C105 12.7861 104.338 13.4554 103.521 13.4554H1.47887C0.662115 13.4554 0 12.7861 0 11.9604V1.49505Z" fill="white"/>
      <path d="M0 139.04C0 138.214 0.662114 137.545 1.47887 137.545H103.521C104.338 137.545 105 138.214 105 139.04V149.505C105 150.331 104.338 151 103.521 151H1.47887C0.662115 151 0 150.331 0 149.505V139.04Z" fill="white"/>
      <path d="M84.1891 133.843C85.0563 133.843 85.7746 133.153 85.7746 132.277C85.7746 114.112 71.2081 99.3857 53.2394 99.3857C35.2707 99.3857 20.7042 114.112 20.7042 132.277C20.7042 133.153 21.4226 133.843 22.2897 133.843H84.1891Z" fill="white"/>
      <path d="M54.7183 84.4703C54.7183 85.7088 53.7251 86.7129 52.5 86.7129C51.2749 86.7129 50.2817 85.7088 50.2817 84.4703C50.2817 83.2318 51.2749 82.2277 52.5 82.2277C53.7251 82.2277 54.7183 83.2318 54.7183 84.4703Z" fill="white"/>
      <path d="M54.7183 93.4406C54.7183 94.6791 53.7251 95.6832 52.5 95.6832C51.2749 95.6832 50.2817 94.6791 50.2817 93.4406C50.2817 92.2021 51.2749 91.198 52.5 91.198C53.7251 91.198 54.7183 92.2021 54.7183 93.4406Z" fill="white"/>
    </svg>
  `,
};
