import * as countries from 'i18n-iso-countries';
import english from 'i18n-iso-countries/langs/en.json';
import german from 'i18n-iso-countries/langs/de.json';


countries.registerLocale(english);
countries.registerLocale(german);

/*
  This function does transform Object with countries into an array
  and also changes order of countries, because
  Germany & Austria must be first & second one in a list of countries
 */
export function processCountries(): { value: string; label: string }[] {

  const allCountriesByCode = countries
    .getNames(
      'de',
      { select: 'official' },
    );

  return Object.entries(allCountriesByCode)
    .reduce((acc: { value: string; label: string}[], [value, label]) => {
      const item = {
        value,
        label,
      };

      switch (value) {
        case 'DE': { // if it's Germany – insert into array beginning
          acc.unshift(item);
        } break;
        case 'AT': { // if it's Austria - insert to second place
          if (acc[0].value === 'DE') {
            acc.splice(1, 0, item);
          } else {
            acc.unshift(item);
          }
        } break;
        default: {
          acc.push(item);
        }
      }

      return acc;
    }, []);
}
