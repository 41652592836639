import { InfoItemType } from '../enums/info-item.type';
import { CategoryType } from '../enums/category.type';
import { FieldType } from '../enums/field.type';


export const itemTypeToFieldType = (itemType: InfoItemType, categoryType: CategoryType): FieldType => {
  switch (categoryType) {
    case CategoryType.WORK:
      switch (itemType) {
        case InfoItemType.EVENT:
          return FieldType.Birthday;
        case InfoItemType.EMAIL:
          return FieldType.WorkEmail;
        case InfoItemType.URL:
          return FieldType.WorkUrl;
        case InfoItemType.ADDRESS:
          return FieldType.WorkAddress;
        case InfoItemType.FULL_ADDRESS:
          return FieldType.WorkFullAddress;
        case InfoItemType.PHONE:
          return FieldType.WorkLandPhone;
        case InfoItemType.MOBILE:
          return FieldType.WorkMobile;
        case InfoItemType.FAX:
          return FieldType.WorkFax;
        case InfoItemType.ORG:
          return FieldType.WorkOrg;
      }
      break;
    case CategoryType.PERSONAL:
      switch (itemType) {
        case InfoItemType.EVENT:
          return FieldType.Birthday;
        case InfoItemType.EMAIL:
          return FieldType.HomeEmail;
        case InfoItemType.URL:
          return FieldType.HomeUrl;
        case InfoItemType.ADDRESS:
          return FieldType.HomeAddress;
        case InfoItemType.FULL_ADDRESS:
          return FieldType.HomeFullAddress;
        case InfoItemType.PHONE:
          return FieldType.HomeLandPhone;
        case InfoItemType.MOBILE:
          return FieldType.HomeMobile;
        case InfoItemType.FAX:
          return FieldType.HomeFax;
        case InfoItemType.ORG:
          return FieldType.HomeOrg;
      }
      break;
    case CategoryType.SHARED:
      switch (itemType) {
        case InfoItemType.EVENT:
          return FieldType.SharedBirthday;
        case InfoItemType.EMAIL:
          return FieldType.HomeEmail;
        case InfoItemType.URL:
          return FieldType.HomeUrl;
        case InfoItemType.ADDRESS:
          return FieldType.HomeAddress;
        case InfoItemType.FULL_ADDRESS:
          return FieldType.HomeFullAddress;
        case InfoItemType.PHONE:
          return FieldType.HomeLandPhone;
        case InfoItemType.MOBILE:
          return FieldType.HomeMobile;
        case InfoItemType.FAX:
          return FieldType.HomeFax;
        case InfoItemType.ORG:
          return FieldType.HomeOrg;
      }
      break;
  }
};
