export enum RouteString {

  NOT_FOUND = 'not-found',
  ERROR = 'error',
  EXPIRED = 'expired',
  /* ----------------------------------------
   * Require token, setup object, listings in sequence
   * -------------------------------------- */
  ANSWERING = 'answering',
  DONE = 'done',
  DOB_AUTH = 'dob-authentication',
  POSTCODE_AUTH = 'postcode-authentication',
  CONFIRMATION = 'confirmation',
}
