export const environment = {
  name: 'production',
  production: true,
  shortlinkUrl: 'https://complero.info/s/',
  hostname: 'https://dashboard.complero.info',
  textsFolder: '/assets/texts/',
  sentryDSM: 'https://7bbc3e0d4454431cb5def1d98ae240e3@glitchtip.infra.complero.info/2',
  appointmentUrl: 'https://datenwaechter.typeform.com/to/gPce4Nl2',
  version: 'COMPLERO.24.46.0',
  sentryPopup: false,
  matomoSiteID: 2,
  shortRequestTimeout: 7000,
  longRequestTimeout: 10000,
};
