export enum FieldType {
  /* ----------------------------------------
   * Person
   * -------------------------------------- */
  Title = 1,
  Salutation,
  FirstName,
  LastName,
  NameSuffix,
  Position,
  Company,
  Website,
  Avatar,
  /* ----------------------------------------
   * Categories
   * -------------------------------------- */
  WorkLandPhone,
  WorkPhone,
  WorkMobile,
  WorkFax,
  WorkEmail,
  WorkAddress,
  WorkFullAddress,
  WorkUrl,
  WorkOrg,
  HomeLandPhone,
  HomePhone,
  HomeMobile,
  HomeFax,
  HomeEmail,
  HomeAddress,
  HomeFullAddress,
  HomeUrl,
  HomeOrg,
  Birthday,
  SharedBirthday,
  Settings,
  SharedAddress,
  SharedFullAddress,
  /* ----------------------------------------
   * Account deleting options (value is used for API)
   * -------------------------------------- */
  DeleteOption1 = 'delete1',
  DeleteOption2 = 'delete2',
  DeleteOption3 = 'delete3',
  DeleteOption4 = 'delete4',
  DeleteOption5 = 'delete5',
  DeleteOption6 = 'delete6',
  DeleteOption7 = 'delete7',
  DeleteOption8 = 'delete8',
  DeleteOption9 = 'delete9',
  DeleteOption10 = 'delete10',

}
