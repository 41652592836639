import { inject, Injectable } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

import { Observable, Subject } from 'rxjs';

import { LoaderScreenComponent } from './components/loader-screen';
import { TimeoutType } from './enums';


@Injectable({
  providedIn: 'root',
})
export class LoaderService {

  private _overlay: Overlay = inject(Overlay);
  private _overlayRef: OverlayRef;
  private _timeoutSubject$ = new Subject<TimeoutType>();
  private _visible = false;

  constructor() {
    this._initOverlay();
  }

  public get timeout$(): Observable<TimeoutType> {
    return this._timeoutSubject$.asObservable();
  }

  public get overlayRef(): OverlayRef | undefined {
    return this._overlayRef;
  }

  public showLoader(): void {
    if (this._visible) {
      return;
    }

    const portal = new ComponentPortal(LoaderScreenComponent);

    this._overlayRef.attach(portal);
    this._visible = true;
  }

  public hideLoader(): void {
    this._overlayRef.detach();
    this._visible = false;
  }

  public showReceiveTimeout(): void {
    this._timeoutSubject$.next(TimeoutType.Receive);
  }

  public showSubmitTimeout(): void {
    this._timeoutSubject$.next(TimeoutType.Submit);
  }

  private _initOverlay(): void {
    const position = this._overlay.position()
      .global()
      .centerHorizontally()
      .centerVertically();

    this._overlayRef = this._overlay.create({
      positionStrategy: position,
      hasBackdrop: true,
    });
  }
}
