import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-startsite-button',
  templateUrl: './startsite-button.component.html',
  styleUrls: ['./startsite-button.component.scss'],
})
export class StartsiteButtonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  goToStartsite() {
    window.location.href = 'https://complero.com/';
  }
}
