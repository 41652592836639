import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import * as Sentry from '@sentry/browser';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';


if (environment.production) {
  enableProdMode();
}

Sentry.init({
  dsn: environment.sentryDSM,
  environment: environment.name,
  release: environment.version,
  autoSessionTracking: false,
  tracesSampleRate: 1.0,
});

platformBrowserDynamic().bootstrapModule(AppModule);
