import { IFieldConfig, IFormField } from '@c-fields/common';

import { IFieldFactory } from '../../common/interfaces/field-factory.interface';

import { MobileField } from './mobile.field';


export class MobileFieldFactory implements IFieldFactory {

  public readonly isComplexField = false;

  public getFieldWithConfig(config: IFieldConfig): IFormField {
    return new MobileField(config);
  }

}
