import { asyncScheduler, Observable, SchedulerAction, Subscriber } from 'rxjs';


export function timeoutCallback(callback: () => any, timeout: number) {
  return function <T>(source: Observable<T>) {
    return new Observable((subscriber: Subscriber<T>) => {
      // create new subscription which should start after timeout
      const callbackSubscription = asyncScheduler
        .schedule(
          function (this: SchedulerAction<any>) {
            callback();

            this.unsubscribe();
          },
          timeout,
        );


      subscriber.add(callbackSubscription);

      return source.subscribe({
        next: (v) => {
          callbackSubscription.unsubscribe();

          subscriber.next(v);
        },
        error: (err) => {
          callbackSubscription.unsubscribe();

          subscriber.error(err);
        },
        complete: () => {
          callbackSubscription.unsubscribe();

          subscriber.complete();
        },
      });
    });
  };
}
