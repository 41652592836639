import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';


import { IRemoteConsent } from '@c-fields/common';
import { FeatureType } from '@common/enums/feature.type';
import { SetupInterface } from '@common/interfaces/setup.interface';
import { Consent } from '@common/classes/consent';
import { timeoutCallback } from '@common/rxjs';
import { syncConsentsInStore } from '@common/helpers/consents/sync-consents-in-store';
import {
  filterAppointmentConsents,
} from '@common/helpers/consents/provinzial/filter-appointment-consents';

import { catchError } from 'rxjs/operators';
import { EMPTY, finalize } from 'rxjs';

import { IRemoteValidationError } from '@c-form';

import { PageType } from '../../../../libs/common/enums/page.type';
import { PersistanceService } from '../../../core/services/persistance.service';
import { StoreService } from '../../../core/services/store.service';
import { environment } from '../../../../environments/environment';
import { LoaderService } from '../../../../libs/loader';
import { ApiService } from '../../../core/services/api.service';


@Component({
  selector: 'app-provinzial-done',
  templateUrl: './provinzial-done.component.html',
  styleUrls: ['./provinzial-done.component.scss'],
})
export class ProvinzialDoneComponent implements OnInit, OnDestroy {

  public pageType = PageType.Answering;
  public appointmentConsents: Consent[] = [];
  public readonly featureType = FeatureType;
  public showConsents = false;
  public sent = false;

  public readonly storeService: StoreService = inject(StoreService);

  private readonly _persistanceService: PersistanceService = inject(PersistanceService);
  private readonly _loaderService: LoaderService = inject(LoaderService);
  private readonly _activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private readonly _apiService: ApiService = inject(ApiService);

  public get setup(): SetupInterface {
    return this.storeService.setup;
  }

  public ngOnInit(): void {
    this._initAppointmentConsents();
    this._updateConsentGivenFlag();
  }

  public ngOnDestroy(): void {
    this._persistanceService.setItem('latestPageType', String(this.pageType));
  }

  public consentsChanged(consents: Consent[]): void {
    syncConsentsInStore(this.storeService.preferences, consents);
  }

  public submit(): void {
    const consents: IRemoteConsent[] = this.storeService.preferences
      .map((c) => {
        return {
          id: String(c.id),
          type: String(c.type),
          given: c.value,
        };
      });

    this._loaderService.showLoader();

    this._apiService.sendAnswer(
      {
        fields: null,
        consents: consents,
      },
      {
        'code': this._activatedRoute.snapshot.queryParams.code,
        'source': this._activatedRoute.snapshot.queryParams.source,
      },
    )
      .pipe(
        timeoutCallback(() => {
          this._loaderService.showSubmitTimeout();
        }, environment.shortRequestTimeout),
        catchError((err: HttpErrorResponse) => {
          if (err.status === 400) {
            const remoteErrors = err.error.field_errors as IRemoteValidationError[];

            // this.formDirective.setRemoteErrors(remoteErrors);
          } else {
            // this.serverErrorMessage.next(err.error?.message);
          }

          return EMPTY;
        }),
        finalize(() => {
          this._loaderService.hideLoader();
        }),
      )
      .subscribe(() => {
        this.sent = true;
        this._updateConsentGivenFlag();
      });
  }

  public openCTALink(): void {
    window.open('https://www.provinzial.de/kundenportal/ueberblick.html', '_blank');
  }

  private _initAppointmentConsents(): void {
    this.appointmentConsents = filterAppointmentConsents(this.storeService.preferences);
  }

  private _updateConsentGivenFlag(): void {
    this.showConsents = this.appointmentConsents.length > 0
      && !this.appointmentConsents.every((consent) => consent.value);
  }
}
