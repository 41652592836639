import { inject, Provider } from '@angular/core';

import { CMP_CONTROL_PATH } from '../tokens/control-path.token';
import { FormControlPath } from '../classes/form-control-path';

/**
 * This provider will be applied for every reactive forms directive in this module
 * and later will be used to build path like "private.emails.0"
 */
export function provideFormControlPath(): Provider {
  return {
    provide: CMP_CONTROL_PATH,
    useFactory: (): FormControlPath => {
      const parent = inject(
        CMP_CONTROL_PATH,
        { skipSelf: true, optional: true },
      ) as (FormControlPath | undefined);

      return new FormControlPath(parent);
    },
  };
}
