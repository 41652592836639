import { Component, OnInit } from '@angular/core';

import { StoreService } from '../../../core/services/store.service';


@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
})
export class ShellComponent implements OnInit {

  /* ----------------------------------------
   * Life-cycles
   * -------------------------------------- */
  // private onDestroySubject = new Subject();
  // onDestroy$ = this.onDestroySubject.asObservable();

  constructor(
    private storeService: StoreService,
  ) {

  }

  ngOnInit() {
    if (
      this.storeService.sender &&
      this.storeService.sender.person &&
      (this.storeService.sender.person.name.firstName || this.storeService.sender.person.name.lastName)) {

      const titleElements = Array.from(document.getElementsByTagName('title'));

      for (const titleElement of titleElements) {
        titleElement.innerText = `${`${this.storeService.sender.person.company}`.trim()} - Complero`;
      }
    }
  }

}
