import { NgModule, ErrorHandler, ENVIRONMENT_INITIALIZER } from '@angular/core';
import { registerLocaleData } from '@angular/common';

import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { en_US, NZ_I18N } from 'ng-zorro-antd/i18n';
import en from '@angular/common/locales/en';
import * as Sentry from '@sentry/angular';
import { NgxMatomoTrackerModule } from '@ngx-matomo/tracker';
import { NgxMatomoRouterModule } from '@ngx-matomo/router';

import { environment } from '../environments/environment';

import { AppComponent } from './components/app/app.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { ShellModule } from './shell/shell.module';
import { HomeComponent } from './components/home/home.component';
import { SharedModule } from './shared/shared.module';
import { LanguageComponent } from './components/language/language.component';
import { DoneModule } from './pages/done/done.module';
import { BirthdayModule } from './pages/birthday/birthday.module';
import { PostcodeAuthModule } from './pages/postcode-auth';
import { RedirectToFirstInSequenceComponent } from './components/redirect-to-first-in-sequence/redirect-to-first-in-sequence.component';
import { StartsiteButtonComponent } from './components/startsite-button/startsite-button.component';
import { CompleroHttpClient } from './core/providers/complero-http-client';
import { debugLoggerFactory } from './factories/debug-logger.factory';
import { AnsweringModule } from './pages/answering/answering.module';


registerLocaleData(en);

@NgModule({ declarations: [
  AppComponent,
  NotFoundComponent,
  HomeComponent,
  LanguageComponent,
  RedirectToFirstInSequenceComponent,
  StartsiteButtonComponent,
],
bootstrap: [AppComponent], imports: [
  // core
  CoreModule.forRoot(),
  // routing
  AppRoutingModule,
  // features
  ShellModule,
  SharedModule,
  AnsweringModule,
  BirthdayModule,
  PostcodeAuthModule,
  DoneModule,
  NgxMatomoTrackerModule.forRoot({
    siteId: environment.matomoSiteID,
    trackerUrl: 'https:////matomo.infra.complero.info/',
  }),
  NgxMatomoRouterModule,
], providers: [
  {
    provide: NZ_I18N,
    useValue: en_US,
  },
  {
    provide: ErrorHandler,
    useValue: Sentry.createErrorHandler({
      showDialog: environment.sentryPopup,
    }),
  },
  {
    provide: HttpClient,
    useClass: CompleroHttpClient,
  },
  {
    provide: ENVIRONMENT_INITIALIZER,
    multi: true,
    useFactory: debugLoggerFactory,
  },
] })
export class AppModule {}
