<label data-cy="field-label" [attr.for]="controlID">
  <ng-content select="field-label"></ng-content>
  <ng-container *ngIf="required">
    <span class="required-star" data-cy="field-control-required-star">&#32;*</span>
  </ng-container>
</label>
<div class="c-form-control">
  <ng-content></ng-content>
</div>

<div class="c-form-control-error" data-cy="field-control-error">
  <ng-container *ngIf="errorState$ | async">
    {{ error$ | async | translate }}
  </ng-container>
</div>
