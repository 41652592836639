import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import fallbackDE from '../../../../../assets/i18n/de.json';


export class TranslationLoader extends TranslateHttpLoader {

  public getTranslation(lang: string): Observable<Object> {
    if (lang === 'de') {
      return of(fallbackDE);
    }

    return super.getTranslation(lang)
      .pipe(
        catchError(() => {
          return of(fallbackDE);
        }),
      );
  }
}
