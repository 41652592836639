import { ChangeDetectionStrategy, Component, Input } from '@angular/core';


@Component({
  selector: 'app-person-section-preview',
  templateUrl: './person-section-preview.component.html',
  styleUrls: ['./person-section-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PersonSectionPreviewComponent {

  @Input()
  public firstName: string | undefined;

  @Input()
  public lastName: string | undefined;

}
