<div class="c-app-content">
  <div class="c-main">
    <div class="c-content">
      <div class="c-img-container">
        <div class="c-img">
          <div [appIcon]="'email_confirmation'" [size]="34" data-cy="confirmation-icon"></div>
        </div>
      </div>
      <div class="c-main-title">
        {{ 'confirmation.email-confirmation.title' | translate }}<br>
      </div>
      <div class="c-main-subtitle">
        {{ 'confirmation.email-confirmation.description-1' | translate }}<br>
        {{ 'confirmation.email-confirmation.description-2' | translate }}
      </div>
    </div>
  </div>
</div>
