import { inject, InjectionToken } from '@angular/core';
import { DOCUMENT } from '@angular/common';


export const LOCAL_STORAGE = new InjectionToken<Storage>(
  'An abstraction over window.localStorage object',
  {
    factory: () => {
      const { defaultView } = inject(DOCUMENT);

      if (!defaultView) {
        throw new Error('Window is not available');
      }

      let storage: Storage | undefined;

      try {
        storage = (defaultView as Window)?.localStorage;
      } catch (e) {
        console.log(e);
        // for cases when access to LocalStorage blocked by client
      }

      return storage;
    },
  },
);
