import { IFieldsData } from '@c-fields/common';

import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { SetupInterface } from '../../../libs/common/interfaces/setup.interface';
import { Side } from '../../../libs/common/classes/side';
import { Consent } from '../../../libs/common/classes/consent';
import { CustomLegal } from '../../../libs/common/classes/custom-legal';


export class StoreService {

  senderSubject = new BehaviorSubject(null);
  senderPhotoSubject = new BehaviorSubject(null);

  sender$ = this.senderSubject.asObservable().pipe(filter((value) => !!value));
  senderPhoto$ = this.senderPhotoSubject.asObservable();

  // used for query parameter to store "web_link_open" event only once
  public initialOpen = true;

  token: string;
  setup: SetupInterface;
  preferences: Consent[];
  sender: Side;
  receiver: Side;
  account: {
    current: {
      name: string;
    }
  };
  customIcons: { [key: string]: string } = {} as { [key: string]: string };
  customLegal: CustomLegal;
  customCopy: { [key: string]: any };
  contractNumber: string;
  confirmationRequested: boolean;

  /* ----------------------------------------
   * To save
   * -------------------------------------- */
  // public sections: Record<string, IFormField[]>;
  public data: IFieldsData;
  folders: any[] = [];
  newAvatarBase64: string;

  /* ----------------------------------------
   * Authentication data
   * -------------------------------------- */
  code: string;
  blocked: boolean;

  private _setupSubject = new ReplaySubject<SetupInterface>(1);
  // eslint-disable-next-line
  public setup$ = this._setupSubject.asObservable().pipe(filter((value) => !!value));

  constructor() {
    this.restoreAuthenticationCodeFromUrl();
  }

  restoreAuthenticationCodeFromUrl() {
    if (location.href.includes('code=')) {
      this.code = location.href.match(/code=(\d*)/)[1];
    }
  }

  setAuthenticationCode(code: string | null) {
    this.code = code;
  }

  setSetup(value) {
    this.setup = value;
    this._setupSubject.next(value);
  }

  setSender(value) {
    this.sender = value;
    this.senderSubject.next(value);
  }

  setSenderVcardPhoto(base64: string) {
    this.senderPhotoSubject.next(base64);
  }

  public initFields(data: any): void {
    this.data = data;
    // this.sections = initializeFields(this.setup.fieldConfigs, data);
  }

  setReceiver(value) {
    this.receiver = value;
  }

  setToken(token: string) {
    this.token = token;
  }

  setPreferences(preferences) {
    this.preferences = preferences;
  }


  setAccount(account) {
    this.account = account;
  }

  setCustomIcons(icons: { [key: string]: string }) {
    this.customIcons = icons;
  }

  deleteAll() {
    this.folders = [];
    this.newAvatarBase64 = '';
    this.setSetup(null);
    this.setSender(null);
    this.setSenderVcardPhoto(null);
    this.setReceiver(null);
    this.setToken(null);
    this.setPreferences(null);
    this.setAccount(null);
    this.setCustomIcons(null);
    this.setCustomLegal(null);
  }

  setCustomLegal(customLegal: any) {
    this.customLegal = customLegal;
  }

  setCustomCopy(customCopy: { [key: string]: any }) {
    this.customCopy = customCopy;
  }

  setContractNumber(contractNumber: string) {
    if (this.setup.showContractNumber) {
      this.contractNumber = contractNumber;
    }
  }

  setRequestConfirmation(confirmationRequested: boolean) {
    this.confirmationRequested = confirmationRequested;
  }

  setBlocked(blocked: boolean) {
    this.blocked = blocked;
  }
}

