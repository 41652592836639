import {
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
} from '@angular/core';
import { NgClass, NgForOf, NgIf } from '@angular/common';

import { SafeHtml } from '@common/pipes/safe.pipe';

import { NgxPrettyCheckboxComponent, NgxPrettySvgDirective } from 'ngx-pretty-checkbox';


import { StoreService } from '../../app/core/services/store.service';
import { Consent } from '../common/classes/consent';
import { PageType } from '../common/enums/page.type';
import { FeatureType } from '../common/enums/feature.type';
import { SetupInterface } from '../common/interfaces/setup.interface';


@Component({
  selector: 'app-consents-list',
  templateUrl: './consents-list.component.html',
  styleUrls: [
    './consents-list.component.scss',
  ],
  standalone: true,
  imports: [
    NgForOf,
    NgIf,
    NgxPrettyCheckboxComponent,
    NgxPrettySvgDirective,
    NgClass,
    SafeHtml,
  ],
})
export class ConsentsListComponent {

  @Input({ alias: 'consents', required: true })
  public consents: Consent[];

  @Input({ required: true })
  public pageType: PageType;

  @Output()
  public changed = new EventEmitter<Consent[]>();

  public readonly featureType = FeatureType;

  private _storeService: StoreService = inject(StoreService);

  public get setup(): SetupInterface {
    return this._storeService.setup;
  }

  public get features(): { [key: number]: boolean } {
    return this.setup.features[this.pageType];
  }

  public change(consent: Consent): void {
    consent.value = !consent.value;

    this.changed.emit(this.consents);
  }
}
