import { InfoItem } from './Info-item';


export class Email extends InfoItem {

  email: string;

  constructor(data: Email) {
    super(data);
    Object.assign(this, data);
  }

  isEmpty?() {
    return !this.email || this.email.trim() === '';
  }

}
