<div class="avatar-container">
  <div *ngIf="avatarUrl" class="avatar custom-avatar">
    <img [src]="avatarUrl">
  </div>
  <div *ngIf="!avatarUrl" [appIcon]="'avatar_placeholder'" [size]="30"
    class="avatar c-avatar-placeholder custom-avatar">

  </div>
</div>

<div class="message-container">
  <div class="message-bubble-arrow custom-message-bubble"></div>
  <ng-content></ng-content>
</div>