export let appConfig: { languages: { default: string; available: string[] } };
appConfig = {
  /*
  *  Add language file name to this list (corresponding with browser language code)
  */
  languages: {
    available: [
      'en',
      'de',
    ],
    // default: 'en'
    // TODO: make English the default language again once language support is reenabled
    default: 'de',
  },
};
