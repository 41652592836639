import { Consent } from '@common/classes/consent';


/**
 * Bad solution to sync with global object.
 * Should be removed ASAP
 */
export function syncConsentsInStore(storeConsents: Consent[], updatedConsents: Consent[]) {
  updatedConsents.forEach((consent: Consent) => {
    const globalConsent = storeConsents.find((preference: Consent) => {
      return consent.id === preference.id
        && consent.type === preference.type
        && consent.title === preference.title;
    });

    globalConsent.value = consent.value;
  });
}
