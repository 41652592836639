import { Injectable } from '@angular/core';

import { StoreService } from './store.service';


@Injectable()
export class TelService {

  constructor(private storeService: StoreService) { }

  // Check for a specific client by the name of flow
  isTel(): boolean {
    return this.storeService.setup.flow.slice(0, 7) === 'flow_t_';
  }

}
