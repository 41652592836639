import { Component, inject } from '@angular/core';

import { SetupInterface } from '@common/interfaces/setup.interface';

import { StoreService } from '../../../core/services/store.service';


@Component({
  templateUrl: './done-route.component.html',
  styleUrls: ['./done-route.component.scss'],
})
export class DoneRouteComponent {

  private readonly _store: StoreService = inject(StoreService);

  public get setup(): SetupInterface {
    return this._store.setup;
  }
}
