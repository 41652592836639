import { inject, Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';

import { isValidURL } from '@common/helpers/is-valid-url';
import { VerificationType } from '@common/enums/verification-type.enum';

import { RouteString } from '../../../libs/common/enums/route.string';
import { PageType } from '../../../libs/common/enums/page.type';

import { StoreService } from './store.service';


@Injectable()
export class AppNavService {

  public currentPageType: PageType;
  public currentPageIndex = 0;
  public document: Document = inject(DOCUMENT);

  constructor(
    private router: Router,
    private _storeService: StoreService,
  ) {}

  goToHome() {
    this.router.navigate(['/']);
  }

  goToNotFound() {
    this.router.navigate([
      RouteString.NOT_FOUND,
    ]);
  }

  public goToVerification(queryParams: Params) {
    if (this._storeService.setup.verificationType === VerificationType.Birthday) {
      this.goToBirthday(queryParams);
    } else if (this._storeService.setup.verificationType === VerificationType.ZipCode) {
      this.goToPostcode(queryParams);
    }
  }

  goToBirthday(queryParams) {
    this.router.navigate([
      'r',
      this._storeService.token,
      RouteString.DOB_AUTH,
    ], { queryParams: queryParams });
  }

  goToPostcode(queryParams) {
    this.router.navigate([
      'r',
      this._storeService.token,
      RouteString.POSTCODE_AUTH,
    ], { queryParams: queryParams });
  }

  goToDonePage(queryParams?: { [key: string]: string | number }) {
    if (this._storeService.token) {
      this.router.navigate([
        'r',
        this._storeService.token,
        RouteString.DONE,
      ], { queryParams: queryParams });
    }
  }

  goToConfirmationPage(queryParams?: { [key: string]: string | number }) {
    if (this._storeService.token) {
      this.router.navigate([
        'r',
        this._storeService.token,
        RouteString.CONFIRMATION,
      ], { queryParams: queryParams });
    }
  }

  goToAnsweringPage(queryParams?: { [key: string]: string | number }) {
    if (this._storeService.token) {
      this.router.navigate([
        'r',
        this._storeService.token,
        RouteString.ANSWERING,
      ], { queryParams: queryParams });
    }
  }

  public goToExpiredTokenPage() {
    this.router.navigate([RouteString.EXPIRED]);
  }

  goToErrorPage() {
    this.router.navigate([RouteString.ERROR]);
  }

  canGoNext(sequence: PageType[], pageType: PageType): boolean {
    return !!this.getNextPageType(sequence, pageType);
  }

  getNextPageType(sequence: PageType[], pageType: PageType): PageType | undefined {
    const nextIndex = sequence.indexOf(pageType) + 1;

    return sequence[nextIndex];
  }

  goToNext(sequence: PageType[], pageType: PageType, queryParams?: { [key: string]: string | number }) {
    const nextPageType = this.getNextPageType(sequence, pageType);

    if (nextPageType) {
      // this.currentStepIndex = stepIndex;
      // this.currentPageType = this.setup.sequence[stepIndex];
      this.goToPageType(nextPageType, queryParams);
    }
  }

  goToPageType(pageType: PageType, queryParams?: { [key: string]: string | number }) {
    switch (pageType) {
      case PageType.DOBAuthentication:
        this.goToBirthday(queryParams);
        break;
      case PageType.SimpleDone:
        this.goToDonePage(queryParams);
        break;
      case PageType.Confirmation:
        this.goToConfirmationPage(queryParams);
        break;
      case PageType.Answering:
        this.goToAnsweringPage(queryParams);
        break;
    }
  }

  setFirstPageOfSequence(pageType: PageType) {
    this.currentPageType = pageType;
  }

  getPageTypeByURL(path: string): PageType {
    switch (path as RouteString) {
      case RouteString.ANSWERING:
        return PageType.Answering;
      case RouteString.DONE:
        return PageType.SimpleDone;
      case RouteString.DOB_AUTH:
        return PageType.DOBAuthentication;
      default:
        break;
    }

  }

  public redirectToExternalURL(url: string): boolean {
    const defaultView: Window = this.document.defaultView;


    if (defaultView && isValidURL(url)) {
      defaultView.open(url, '_self');

      return true;
    }

    return false;
  }
}
