export enum FieldInputType {
  // Email
  Email   = 'email-address',

  // Address
  City    = 'city',
  Street  = 'street',
  Country = 'country',
  ZipCode = 'zip-code',
  HouseNumber   = 'house-number',
  Addition = 'addition',
  Extra1 = 'extra1',
  Extra2 = 'extra2',
  Extra3 = 'extra3',

  // First Name
  FirstName = 'first-name',
  LastName = 'last-name',

  // Salutation
  Salutation = 'salutation',

  // Birthday
  Birthday   = 'birthday',

  // Phones
  Mobile   = 'mobile',
  Phone   = 'phone',

  // Title
  Title = 'title',
}
