import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';
import { CollapsableSectionComponent } from '../../../libs/collapsable-section';
import { ConsentsListComponent } from '../../../libs/consents-list/consents-list.component';

import { SimpleDoneComponent } from './simple-done/simple-done.component';
import { ProvinzialDoneComponent } from './provinzial-done/provinzial-done.component';
import { DoneRouteComponent } from './done-route/done-route.component';


@NgModule({
  declarations: [
    DoneRouteComponent,
    SimpleDoneComponent,
    ProvinzialDoneComponent,
  ],
  imports: [
    SharedModule,
    CollapsableSectionComponent,
    ConsentsListComponent,
  ],
})
export class DoneModule { }
