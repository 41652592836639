import { IFieldRemoteConfig } from '../interfaces/field-remote-config.interface';
import { remoteToFieldConfig } from '../mappers/remote-to-field-config.mapper';
import { IFieldConfig } from '../interfaces/field-config.interface';
import { stringToRemoteConfig } from '../mappers/string-to-remote-config';


/**
 * Factory does transformation for Field Configurations received from Server into Web App entites
 * Here can be implemented logic to ensure that Web App receives only configs which it can recognise
 */
export class FieldConfigsFactory {

  /**
   * In this case we just do direct mapping, nothing special
   */
  public static buildFromConfigObjects(configs: IFieldRemoteConfig[]): IFieldConfig[] {
    return configs.map((config) => {
      return remoteToFieldConfig(config);
    });
  }

  /**
   * This is more complicated and risky method
   * which we need for support Field Configurations as a Strings
   */
  public static buildFromConfigStrings(configs: string[]): IFieldConfig[] {
    const remoteConfigs = configs.map((config) => {
      return stringToRemoteConfig(config);
    });

    return this.buildFromConfigObjects(remoteConfigs);
  }

}
