import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { FieldInputType } from '@c-fields/common';

import { isDateValid } from './date/is-date-valid';


export class CValidators {

  public static birthday(control: AbstractControl): { [key: string]: any } {
    if (!control.value) {
      return null;
    }

    if (!isDateValid(control.value)) {
      return { birthday: true };
    }


    const maxDate = new Date();
    const minDate = new Date(maxDate.getFullYear() - 150, maxDate.getMonth(), maxDate.getDate());
    if (control.value < minDate || control.value > maxDate) {
      return { birthday: true };
    }


    return null;
  }

  public static houseNumber(control: AbstractControl): { [key: string]: any } {
    if (!control.value) {
      return null;
    }

    const houseRegex = /^[a-zA-Z0-9 \/.-]*$/;
    if (!houseRegex.test(String(control.value))) {
      return { houseNumber: true };
    }

    if (control.value.length > 35) {
      return { houseNumber: true };
    }


    return null;
  }

  public static zipCode(
    countryControl: AbstractControl,
    zipCodeControl: AbstractControl,
  ):  ValidatorFn {
    return (_: AbstractControl): ValidationErrors | null => {
      if (!zipCodeControl.value) {
        return null;
      }

      if (countryControl.value === 'DE') {
        if (!validateDEZipCode(zipCodeControl.value)) {
          zipCodeControl.setErrors({ zipCode: true });

          return { zipCode: true };
        }
      } else {
        if (!validateZipCode(zipCodeControl.value)) {
          zipCodeControl.setErrors({ zipCode: true });

          return { zipCode: true };
        }
      }

      zipCodeControl.setErrors(null);

      return null;
    };
  }

  public static phoneNumber(fieldType: FieldInputType.Mobile | FieldInputType.Phone)
    : (control: AbstractControl) => { [key: string]: any } {

    const errorKey = fieldType === FieldInputType.Phone
      ? 'phoneNumber'
      : 'mobileNumber';

    return (control: AbstractControl) => {
      if (!control.value) {
        return null;
      }

      const houseRegex = /^[0-9\s()\\\/#+-]*$/;
      if (!houseRegex.test(String(control.value))) {
        return { [errorKey]: true };
      }

      return null;
    };
  }

  public static firstName(control: AbstractControl): { [key: string]: any } {
    if (!control.value) {
      return null;
    }


    if (containNumbers(control.value)) {
      return {
        firstName: true,
      };
    }

    return null;
  }

  public static lastName(control: AbstractControl): { [key: string]: any } {
    if (!control.value) {
      return null;
    }


    if (containNumbers(control.value)) {
      return {
        lastName: true,
      };
    }

    return null;
  }

  public static emailDomainName(control: AbstractControl): { [key: string]: any } {
    if (!control.value) {
      return null;
    }


    if (!naiveDomainValidation(control.value)) {
      return {
        email: true,
      };
    }

    return null;
  }
}

export function validateDEZipCode(value: string) {
  const zipcodeRegex = /^[0-9]{5}$/;

  return zipcodeRegex.test(String(value));
}

export function validateZipCode(value: string) {
  const zipcodeRegex = /^[0-9a-zA-Z]{2,8}$/;

  return zipcodeRegex.test(String(value));
}

export function containNumbers(value: string): boolean {
  const numbersRegex = /\d/;

  return numbersRegex.test(String(value));
}

export function naiveDomainValidation(value: string): boolean {
  const naiveEndDomainRegex = /\..{2,}$/;

  return naiveEndDomainRegex.test(String(value));
}
