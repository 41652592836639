import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';

import { PostcodeVerificationComponent } from '../../../libs/code-input/postcode-verification';

import { PostcodeAuthComponent } from './postcode/postcode-auth.component';


@NgModule({
  declarations: [PostcodeAuthComponent],
  exports: [
    PostcodeAuthComponent,
  ],
  imports: [
    SharedModule,
    PostcodeVerificationComponent,
  ],
})
export class PostcodeAuthModule { }
